import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { usersApi } from "../../api";
import {
  APP_ROUTES,
  ERROR_MESSAGES,
  IAuthResponse,
  IResponseError,
  ModalType,
} from "../../data-access";
import { storeAuthActions } from "../../store/slices/auth";
import { storeBillingActions } from "../../store/slices/billing";
import {
  cookie,
  encryption,
  handleFailedRequest,
  updateExtensionCookies,
} from "../../util";
import { storeModalsActions } from "../../store/slices/modals";

export const Auth = (): null => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const accessCode = queryParams.get("access_code");
    const isRegistration: any = queryParams.get("isRegistration");

    const showInvitationModal = (): void => {
      dispatch(
        storeModalsActions.open({
          type: ModalType.Welcome,
        })
      );
    };

    if (!email || !accessCode) {
      navigate(APP_ROUTES.login);
    } else {
      usersApi
        .checkAccessCodeValidity({ email, access_code: accessCode })
        .then((response: IAuthResponse) => {
          const authDetails = {
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            user: response.user,
          };

          dispatch(storeAuthActions.setUserData(authDetails));

          if (response.user.promocode) {
            dispatch(
              storeBillingActions.update({ discount: response.user.promocode })
            );
          }

          cookie.set("exSM", encryption.generateHash("authorized"));
          updateExtensionCookies(authDetails);
          navigate(APP_ROUTES.dashboard);
          if (
            isRegistration &&
            (isRegistration === "1" || isRegistration === 1)
          ) {
            showInvitationModal();
          }
        })
        .catch((error: IResponseError) => {
          navigate(APP_ROUTES.login);
          handleFailedRequest(
            error.status,
            ERROR_MESSAGES.AUTH_FAIL,
            error?.details,
            error.text
          );
        });
    }
  }, [navigate, dispatch]);

  return null;
};
