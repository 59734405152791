import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tag } from "antd";
import { usersApi } from "../../api";
import {
  APP_ROUTES,
  ColumnAlign,
  IGetProposalsResponse,
  IProposal,
  ProfileFilterCriteriaType,
} from "../../data-access";
import { FormButton, Panel, DataTable, BackButton } from "../../ui";
import { notify, querify } from "../../util";
import "./proposals.scss";

const baseClass = "proposals";

interface IProfileUser {
  upwex_user_email: string;
  upwork_user_name: string;
}

export const Proposals = (): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [proposals, setProposals] = useState<IProposal[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [proposalsPerPage, setProposalsPerPage] = useState<number>(10);
  const [totalProposals, setTotalProposals] = useState<number>(0);
  const [sortingParams, setSortingParams] = useState({});
  const [userData, setUserData] = useState<IProfileUser>({} as IProfileUser);
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const upworkUserId = params.get("upwork_user_id");
  const upwexUserId = params.get("upwex_user_id");
  const timePeriod = params.get("time_period");
  const dateFrom = params.get("date_from");
  const dateTo = params.get("date_to");

  const TABLE_COLUMNS = [
    { displayName: "Job Title", textAlign: ColumnAlign.LEFT },
    { displayName: "View Proposal" },
    { displayName: "Used Connects", field: "used_connects", sortable: true },
    { displayName: "Is Reply", field: "is_reply", sortable: true },
  ];

  useEffect(() => {
    document.body.classList.add("proposals-page");

    if (!upworkUserId || !upwexUserId) {
      navigate(APP_ROUTES.dashboard);
      return;
    }

    setIsProcessing(true);

    usersApi
      .getProposals({
        upwork_user_id: upworkUserId,
        upwex_user_id: upwexUserId,
        page: String(currentPage),
        per_page: proposalsPerPage,
        ...(!!timePeriod
          ? { time_period: timePeriod as ProfileFilterCriteriaType }
          : undefined),
        ...(!!dateFrom ? { date_from: dateFrom as string } : undefined),
        ...(!!dateTo ? { date_to: dateTo as string } : undefined),
        ...(Object.keys(sortingParams).length > 0 && {
          sort_by: querify(sortingParams, ":", ","),
        }),
      })
      .then((response: IGetProposalsResponse) => {
        const {
          data,
          pagination: {
            current_page: currentResultPage,
            per_page: perPage,
            total,
          },
        } = response;
        const indexedProposals = data.map(
          (proposal: IProposal, index: number) => {
            const startIndex =
              currentResultPage > 1
                ? (currentPage - 1) * perPage
                : currentPage - 1;
            return {
              ...proposal,
              index: startIndex + index + 1,
            };
          }
        );
        setProposals(indexedProposals);
        setTotalProposals(total);
        setUserData(response.user_data);
        setCurrentPage(currentResultPage);
      })
      .catch(
        (error: {
          status: number;
          reason?: string;
          text?: string;
          message?: string;
        }) => {
          notify(
            "error",
            error?.message || error?.text || "Failed to fetch proposals"
          );
        }
      )
      .finally(() => setIsProcessing(false));

    return () => {
      document.body.classList.remove("proposals-page");
    };
  }, [currentPage, sortingParams, proposalsPerPage]);

  const handleProposalOpen = (id: string): void => {
    window.open(`https://www.upwork.com/ab/proposals/${id}`, "_blank");
  };

  const handleJobTitleClick = (id: string): void => {
    window.open(`https://www.upwork.com/jobs/~${id}`, "_blank");
  };

  const handleSorting = (sorting: {
    field: string;
    order: "asc" | "desc" | null;
  }): void => {
    if (sorting.field) {
      setSortingParams({
        [sorting.field]: sorting.order,
      });
    }
  };

  const handlePagination = (perPage: number, requestedPage: number): void => {
    setCurrentPage(requestedPage);
    setProposalsPerPage(perPage);
  };

  const getTableData = () => {
    return proposals.map((proposal) => ({
      job_title: (
        <strong
          className="job-title"
          onClick={() => handleJobTitleClick(proposal.job_id)}
        >
          {proposal.job_title}
        </strong>
      ),
      view_proposal: (
        <FormButton onClick={() => handleProposalOpen(proposal.proposal_id)}>
          Open
        </FormButton>
      ),
      used_connects: (
        <span className="centered">
          {proposal.used_connects ?? <Tag color="green">None</Tag>}
        </span>
      ),
      is_reply: (
        <span className="centered">
          <Tag color={proposal.is_reply ? "green" : "red"}>
            {proposal.is_reply ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    }));
  };

  return (
    <div className={baseClass}>
      <BackButton to={APP_ROUTES.dashboard}>Back to Dashboard</BackButton>
      <Panel title="Proposals">
        <div className="user-name">
          <b>{userData.upwork_user_name}</b>, {userData.upwex_user_email}
        </div>
        <DataTable
          isLoading={isProcessing}
          columns={TABLE_COLUMNS}
          perPage={proposalsPerPage}
          currentPage={currentPage}
          data={getTableData()}
          customPagination={true}
          onPagination={handlePagination}
          onSorting={handleSorting}
          totalRecords={totalProposals}
          totals={[{ label: "Total", value: totalProposals, colspan: 5 }]}
        />
      </Panel>
    </div>
  );
};
