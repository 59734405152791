import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { ReactComponent as HappyFaceSVG } from "../../../../assets/images/happy-smile.svg";
// @ts-ignore
import { ReactComponent as SadFaceSVG } from "../../../../assets/images/sad-smile.svg";
import { billingApi } from "../../../../api";
import { ILicence, IResponseError } from "../../../../data-access";
import { storeBillingActions } from "../../../../store/slices/billing";
import { RootState } from "../../../../store";
import { FormButton, ModalDialog } from "../../../../ui";
import { handleFailedRequest, notify } from "../../../../util";
import "./subscription-check-status-modal.scss";

interface ISubscriptionCheckStatusModalProps {
  onClose: () => void;
  licenseId?: string;
  failReason?: string;
}

export const SubscriptionCheckStatusModal = ({
  onClose,
  licenseId,
  failReason = "",
}: ISubscriptionCheckStatusModalProps): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isCloseIconHovered, setIsCloseIconHovered] = useState<boolean>(false);
  const availableLicences = useSelector(
    (state: RootState) => state.billing.licences
  );
  const dispatch = useDispatch();

  const toggleCloseIconHover = (): void => {
    setIsCloseIconHovered(!isCloseIconHovered);
  };

  const handleRenew = (): void => {
    if (licenseId) {
      setIsProcessing(true);

      const licencesClone = [...availableLicences];
      const targetLicenceIdx = licencesClone.findIndex(
        (licence: ILicence) => licence.id === licenseId
      );

      billingApi
        .renewBillingLicence(licenseId)
        .then((response: { data: ILicence }) => {
          licencesClone[targetLicenceIdx] = response.data;
          dispatch(
            storeBillingActions.update({
              licences: licencesClone,
            })
          );
          notify("success", "Subscription has been successfully updated.");
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text, error.details);
        })
        .finally(() => {
          setIsProcessing(false);
          onClose();
        });
    }
  };

  const removeFailedSubscription = (id: string): void => {
    const licencesList = [...availableLicences];
    const canceledSubscriptionIdx = licencesList.findIndex(
      (sub: ILicence) => sub.id === id
    );
    licencesList.splice(canceledSubscriptionIdx, 1);
    dispatch(
      storeBillingActions.update({
        licences: licencesList,
      })
    );
  };

  const handleDelete = (): void => {
    if (licenseId) {
      setIsProcessing(true);

      billingApi
        .deleteBillingLicence(licenseId)
        .then(() => {
          removeFailedSubscription(licenseId);
          notify("success", `Subscription has been successfully removed.`);
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text, error.details);
        })
        .finally(() => {
          setIsProcessing(false);
          onClose();
        });
    }
  };

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  };

  const ModalDialogTitle = useMemo(
    () => (
      <div className="row">
        Payment Failed
        <div className="svg-icon">
          {isCloseIconHovered ? <HappyFaceSVG /> : <SadFaceSVG />}
        </div>
      </div>
    ),
    [isCloseIconHovered]
  );

  return (
    <ModalDialog
      title={ModalDialogTitle}
      className="cancel-popup payment-check-status-popup"
      confirmText="Renew"
      isOpen={true}
      closeIcon={<CloseOutlined />}
      footer={[
        <>
          <FormButton
            theme="red"
            disabled={isProcessing}
            loading={isProcessing}
            onClick={handleDelete}
          >
            Delete
          </FormButton>
          <FormButton
            theme="blue"
            disabled={isProcessing}
            loading={isProcessing}
            onClick={handleRenew}
            onMouseEnter={toggleCloseIconHover}
            onMouseLeave={toggleCloseIconHover}
          >
            Renew
          </FormButton>
        </>,
      ]}
      onCancel={handleClose}
    >
      <p className="cancel-popup__body">
        <span className="cancel-popup__main-text">
          Your payment was not received.
        </span>
        <span className="cancel-popup__main-text">
          <strong>Reason:</strong> {failReason}
        </span>
      </p>
    </ModalDialog>
  );
};

SubscriptionCheckStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  licenseId: PropTypes.string,
  failReason: PropTypes.string,
};
