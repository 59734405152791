import { ReactNode } from "react";
import PropTypes from "prop-types";
import "./information-block.scss";
import { FaBullhorn } from "react-icons/fa";

const baseClassName = "information-block";

interface IInformationBlockProps {
  children: string | ReactNode;
  isSpecial?: boolean;
}

export const InformationBlock = ({
  children,
  isSpecial = false,
}: IInformationBlockProps): JSX.Element => {
  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__icon`}>
        {!isSpecial && <img src="light-bulb.png" />}
        {isSpecial && <FaBullhorn className="icon" />}
      </div>
      <p className={`${baseClassName}__paragraph`}>{children}</p>
    </div>
  );
};

InformationBlock.propTypes = {
  children: PropTypes.node.isRequired,
};
