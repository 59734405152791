import { NavLink } from 'react-router-dom';
import {
  FaLightbulb
} from 'react-icons/fa';
import { appConfig } from '../../app-config';
import { APP_ROUTES, RequestedFeaturesStatusIndicatorColor, RequestedFeatureStatusTitle } from '../../data-access';
import { useUser } from '../../hooks';
import { ReorderableRequestedFeatures } from './reorderable-feature-columns';
import { RequestedFeature } from './requested-feature';
import { RequestedFeatureColumn } from './requested-feature-column';
import './roadmap.scss';

const baseClass = 'roadmap';

const requestedFeaturesColumns = [
  { title: 'Under Consideration', color: RequestedFeaturesStatusIndicatorColor.under_consideration },
  { title: 'Up Next', color: RequestedFeaturesStatusIndicatorColor.up_next },
  { title: 'In Progress', color: RequestedFeaturesStatusIndicatorColor.in_progress },
  { title: 'Shipped', color: RequestedFeaturesStatusIndicatorColor.shipped },
];

const requestedFeaturesByStatus = [
  [
    { title: 'Some Feature', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Create something...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Help me with...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Get new...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Check if...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Allow users...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Count number...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Restrict from...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Delete general...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
  ],
  [
    { title: 'New address...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Add new city...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Shipping details...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
  ],
  [
    { title: 'Something regarding...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Optimisation of...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Make user indicator...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Create roadmap for...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'SEO optimisation...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
  ],
  [
    { title: 'Adjusted general structure...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Check messaging app...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'Prepare data...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
    { title: 'All is great...', upVotes: 10, downVotes: 5, id: String(Math.floor(Math.random() * 100)) },
  ],
];

export const Roadmap = (): JSX.Element => {
  const { currentUser } = useUser();

  const hasRightToManageRequestedFeatures = currentUser && appConfig.featuresRequest.allowedUsersEmails.includes(currentUser.email);

  return (
    <div className={baseClass}>
      <h1 className={`${baseClass}__main-title`}>
        Roadmap
        <NavLink className={`${baseClass}__link`} to={APP_ROUTES.featureRequest}>
          <FaLightbulb />Feature request
        </NavLink>
      </h1>
      {hasRightToManageRequestedFeatures && (
        <ReorderableRequestedFeatures features={requestedFeaturesByStatus} />
      )}
      {!hasRightToManageRequestedFeatures && (
        <div className={`${baseClass}__statuses`}>
          {requestedFeaturesColumns.map((column, index: number) => (
            <RequestedFeatureColumn key={column.title} title={column.title as RequestedFeatureStatusTitle} indicatorColor={column.color}>
              {requestedFeaturesByStatus[index].map((feature) => (
                <RequestedFeature key={feature.id} data={feature} />
              ))}
            </RequestedFeatureColumn>
          ))}
        </div>
      )}
    </div>
  );
};