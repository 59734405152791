import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IAnalytics } from '../../../data-access';
import { StatisticsMetric } from '../statistics-metric';
import {
  FaBriefcase,
  FaComments,
  FaEnvelope,
  FaPercent,
  FaRobot,
  FaRocket,
  FaSyncAlt,
  FaTrophy,
  FaUserPlus,
  FaConnectdevelop,
  FaTachometerAlt,
  FaMoneyBill,
} from 'react-icons/fa';
import { RootState } from '../../../store';
import { Spinner } from '../../../ui';
import './general-statistics.scss';

interface IGeneralStatisticsProps {
  stats: IAnalytics;
  isLoading?: boolean;
}

const baseClass = 'general-statistics';

export const GeneralStatistics = ({ stats, isLoading = false }: IGeneralStatisticsProps): JSX.Element => {
  const isPipedriveConnected = useSelector((state: RootState) => state.tools.is_pipedrive_connected);

  const formattedReplyRate = stats?.reply_rate ? Number(stats.reply_rate).toFixed(2) : 0;

  const calculateReplyCost = (usedConnects = 0, replies = 0): string => {
    return replies > 0 ? (usedConnects / 300 * 45 / replies).toFixed(2) : '0';
  }

  const calculateAvgConnects = (usedConnects = 0, bids = 0): string => {
    return bids > 0 ? (usedConnects / bids).toFixed(2) : '0';
  }

  return (
    <div className={`${baseClass} column ${isLoading ? 'fetching' : ''}`}>
      {isLoading && <Spinner />}
      <div className={`${baseClass}__metrics`}>
        <StatisticsMetric
          icon={<FaBriefcase />}
          metric="Open Job"
          value={`${stats?.opened_jobs ?? 0}`}
        />
          <StatisticsMetric
          icon={<FaTrophy />}
          metric="BID"
          value={`${stats?.bids ?? 0}`}
        />
        <StatisticsMetric
          icon={<FaEnvelope />}
          metric="Reply"
          value={`${stats?.replies ?? 0}`}
        />
        <StatisticsMetric
          icon={<FaPercent />}
          metric="Reply Rate"
          value={`${formattedReplyRate}`}
        />
        <StatisticsMetric
          icon={<FaUserPlus />}
          metric="Invites"
          value={`${stats?.invites ?? 0}`}
        />
        <StatisticsMetric
          icon={<FaConnectdevelop />}
          metric="Connects"
          value={`${stats?.used_connects ?? 0}`}
        />
        <StatisticsMetric
          icon={<FaTachometerAlt />}
          metric="AVG Connects"
          value={calculateAvgConnects(stats?.used_connects, stats?.bids)}
        />
        <StatisticsMetric
          icon={<FaMoneyBill />}
          metric="Reply Cost ($)"
          value={calculateReplyCost(stats?.used_connects, stats?.replies)}
          tooltip={
            <span>The calculation is made taking into account that you buy 300 upwork connections. <br />Reply Сost = Used Connects / 300 * 45$ / Replies</span>
          }
        />
        <StatisticsMetric
          icon={<FaComments />}
          metric="Direct Messages"
          value={`${stats?.direct_messages ?? 0}`}
        />
        <StatisticsMetric
          icon={<FaRobot />}
          metric="Generated AI Proposal"
          value={`${stats?.ai_proposals ?? 0}`}
        />
        <StatisticsMetric
          icon={<FaRocket />}
          metric="Generated AI Q/A"
          value={`${stats?.ai_answers ?? 0}`}
        />
        {isPipedriveConnected && (
          <StatisticsMetric
            icon={<FaSyncAlt />}
            metric="Sync with CRM"
            value={`${stats?.sync_with_crms ?? 0}`}
          />
        )}
      </div>
    </div>
  );
}

GeneralStatistics.propTypes = {
  stats: PropTypes.shape({
    ai_answers: PropTypes.number,
    ai_proposals: PropTypes.number,
    bids: PropTypes.number,
    opened_jobs: PropTypes.number,
    replies:PropTypes.number,
    reply_rate:PropTypes.number,
    sync_with_crms: PropTypes.number,
    direct_messages: PropTypes.number,
    invites: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool,
};