import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import './no-records-panel.scss';

const baseClass = 'no-records-panel';

interface INoRecordsPanelProps {
  children: string | ReactNode;
}

export const NoRecordsPanel = ({ children }: INoRecordsPanelProps): JSX.Element => (
  <div className={baseClass}>{children}</div>
);

NoRecordsPanel.propTypes = {
  children: PropTypes.string.isRequired
};