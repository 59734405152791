import {
  Checkbox,
  Col,
  Divider,
  Drawer,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import "./search-filters.scss";
import {
  clientHistoryOptions,
  clientLocations,
  clientTimezones,
  experienceLevelOptions,
  fixedPriceOptions,
  hoursPerWeekOptions,
  projectLengthOptions,
  proposalsOptions,
  transformDataToSearchString,
  transformUrlToData,
  upworkCategories,
} from "./filters";
import { useEffect, useState } from "react";
import { FormButton } from "../../../ui";

interface ISearchFiltersProps {
  searchUrl: any;
  onClose: () => void;
  onSave: (url: string) => void;
  open?: boolean;
}

const baseClassName = "search-filters";

export const SearchFilters = ({
  searchUrl,
  onClose,
  onSave,
  open = false,
}: ISearchFiltersProps): JSX.Element => {
  const [filters, setFilters] = useState<any>({
    subcategory2_uid: [],
    contractor_tier: [],
    amount: [],
    proposals: [],
    client_hires: [],
    location: [],
    timezone: [],
    duration_v3: [],
    workload: [],
    payment_verified: false,
    previous_clients: false,
    contract_to_hire: false,
    min: null,
    max: null,
    q: "",
  });

  useEffect(() => {
    const paramsFromUrl = transformUrlToData(searchUrl);
    setFilters(paramsFromUrl);
  }, [searchUrl]);

  const handleChange = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleSave = () => {
    const urlParams = transformDataToSearchString(filters);
    const searchUrl = `https://www.upwork.com/nx/search/jobs/?per_page=50&sort=recency${encodeURI(
      urlParams
    )}`;
    onSave(searchUrl);
  };

  return (
    <Drawer title="Filters" width={"60%"} onClose={onClose} open={open}>
      <div className={`${baseClassName}__container`}>
        <div className={`${baseClassName}__left`}>
          <p className={`${baseClassName}__title`}>Title search</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Input
                value={filters.q}
                onChange={(value) => handleChange("q", value.target.value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Category</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select categories"
                value={filters.subcategory2_uid}
                onChange={(value) => handleChange("subcategory2_uid", value)}
                options={upworkCategories}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Experience level</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox.Group
                options={experienceLevelOptions}
                value={filters.contractor_tier}
                onChange={(value) => handleChange("contractor_tier", value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Job type</p>
          <p className={`${baseClassName}__title small`}>Hourly</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Space>
                <InputNumber<number>
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) =>
                    value?.replace(/\$\s?|(,*)/g, "") as unknown as number
                  }
                  addonBefore="Min"
                  value={filters.min}
                  onChange={(value) => handleChange("min", value)}
                />
                <InputNumber<number>
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) =>
                    value?.replace(/\$\s?|(,*)/g, "") as unknown as number
                  }
                  addonBefore="Max"
                  value={filters.max}
                  onChange={(value) => handleChange("max", value)}
                />
              </Space>
            </Col>
          </Row>
          <p className={`${baseClassName}__title small`}>Fixed-Price</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox.Group
                options={fixedPriceOptions}
                value={filters.amount}
                onChange={(value) => handleChange("amount", value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Number of proposals</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox.Group
                options={proposalsOptions}
                value={filters.proposals}
                onChange={(value) => handleChange("proposals", value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Client info</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox
                checked={filters.previous_clients}
                onChange={(value) =>
                  handleChange("previous_clients", value.target.checked)
                }
              >
                My previous clients
              </Checkbox>
              <Checkbox
                checked={filters.payment_verified}
                onChange={(value) =>
                  handleChange("payment_verified", value.target.checked)
                }
              >
                Payment verified
              </Checkbox>
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Client history</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox.Group
                options={clientHistoryOptions}
                value={filters.client_hires}
                onChange={(value) => handleChange("client_hires", value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Client location</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select client locations"
                value={filters.location}
                onChange={(value) => handleChange("location", value)}
                options={clientLocations}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Client time zones</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select client time zones"
                value={filters.timezone}
                onChange={(value) => handleChange("timezone", value)}
                options={clientTimezones}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Project length</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox.Group
                value={filters.duration_v3}
                options={projectLengthOptions}
                onChange={(value) => handleChange("duration_v3", value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Hours per week</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox.Group
                options={hoursPerWeekOptions}
                value={filters.workload}
                onChange={(value) => handleChange("workload", value)}
              />
            </Col>
          </Row>
          <p className={`${baseClassName}__title`}>Job duration</p>
          <Row className="space-bottom">
            <Col span={24}>
              <Checkbox
                checked={filters.contract_to_hire}
                onChange={(value) =>
                  handleChange("contract_to_hire", value.target.checked)
                }
              >
                Contract-to-hire roles
              </Checkbox>
            </Col>
          </Row>
          <Divider />
          <FormButton theme="green" onClick={() => handleSave()}>
            Save
          </FormButton>
        </div>
      </div>
    </Drawer>
  );
};
