import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { useForm, Controller, Control } from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import { settingsApi } from "../../../api";
import {
  IGetSettingsResponse,
  IResponseError,
  ICrmSettings,
  IAISettings,
} from "../../../data-access";
import { store } from "../../../store";
// import { storeSettingsActions } from "../../../store/slices/settings";
import { FormSwitch, FormButton } from "../../../ui";
import { notify, handleFailedRequest } from "../../../util";
import "./ai-settings-form.scss";

interface ISimpleSwitchToggleSettingProps {
  name: keyof IAISettings;
  control: Control<IAISettings>;
  disabled?: boolean;
  label: string;
  tooltipText?: string;
}

const SimpleSwitchToggleSetting = ({
  name,
  control,
  disabled,
  label,
  tooltipText,
}: ISimpleSwitchToggleSettingProps): JSX.Element => {
  return (
    <div className="setting">
      <div className="setting__label">
        {label}
        <Tooltip title={tooltipText} color="#fff" key={name}>
          <FaInfoCircle />
        </Tooltip>
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <FormSwitch
            ref={ref}
            disabled={disabled}
            onChange={onChange}
            isChecked={!!value}
          />
        )}
      />
    </div>
  );
};

interface ICrmSettingsFormState {
  [key: string]: {
    title: string;
    value: boolean;
    expandable: boolean;
    tooltipText: string;
  };
}

interface ICrmSettingsFormProps {
  isFetching: boolean;
}

export const AISettingsForm = ({
  isFetching,
}: ICrmSettingsFormProps): JSX.Element => {
  const storeBasedSettings = store.getState().settings
    .job_assistant as IAISettings;
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);

  const [formSettings] = useState<ICrmSettingsFormState>({
    automatic_job_ai_assistant: {
      title: "Enable automatically AI Job Assistant",
      value: storeBasedSettings.automatic_job_ai_assistant,
      expandable: false,
      tooltipText: "Enable automatically AI Job Assistant.\n",
    },
  });

  const form = useForm<IAISettings>({
    mode: "all",
    defaultValues: {
      automatic_job_ai_assistant: storeBasedSettings.automatic_job_ai_assistant,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isValid },
  } = form;

  const handleFormSubmit = (formFields: IAISettings): void => {
    setIsProcessingForm(true);

    settingsApi
      .updateAIJobSettings(formFields)
      .then((response: { data: any }) => {
        notify("success", "AI Job Assistant Settings has been updated!");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingForm(false));
  };

  const isFormBeenModified = Object.keys(dirtyFields).length > 0;
  const isCRMElementDisabled = isProcessingForm;

  return (
    <form className="settings-form" onSubmit={handleSubmit(handleFormSubmit)}>
      {!isFetching && (
        <div className="crm-settings column">
          {Object.keys(formSettings).map((key: string) => {
            const settingKey = key as keyof IAISettings;

            return (
              <div className="column" key={settingKey}>
                {!formSettings[settingKey]?.expandable && (
                  <SimpleSwitchToggleSetting
                    control={control}
                    disabled={isCRMElementDisabled}
                    label={formSettings[settingKey].title}
                    name={settingKey}
                    tooltipText={formSettings[settingKey].tooltipText}
                  />
                )}
                {formSettings[settingKey]?.expandable && (
                  <div className="expandable-setting">
                    <SimpleSwitchToggleSetting
                      control={control}
                      disabled={isCRMElementDisabled}
                      label={formSettings[settingKey].title}
                      name={settingKey}
                      tooltipText={formSettings[settingKey].tooltipText}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <FormButton
        className="form-button"
        htmlType="submit"
        loading={isProcessingForm}
        disabled={!isFormBeenModified || !isValid || isProcessingForm}
      >
        Save
      </FormButton>
    </form>
  );
};

export default AISettingsForm;
