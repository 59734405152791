import PropTypes from 'prop-types';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'antd';
import './statistics-metric.scss';

interface IStatisticsMetricProps {
  icon: JSX.Element;
  metric: string;
  value: string;
  tooltip?: string | React.ReactNode;
}

export const StatisticsMetric = ({ icon, metric, value, tooltip }: IStatisticsMetricProps): JSX.Element => {
  return (
    <div className="parameter">
      <div className="icon">{icon}</div>
      <div className="details">
        <span className="value">{value}</span>
        <span className="title">
          {metric}
          {tooltip && (
            <Tooltip title={tooltip} color="#fff">
              <FaInfoCircle />
            </Tooltip>
          )}
        </span>
      </div>
    </div>
  );
}

StatisticsMetric.propTypes = {
  icon: PropTypes.node.isRequired,
  metric: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};