import PropTypes from 'prop-types';
import { Select } from 'antd';
import { capitalize } from '../../../util';
import './select.scss';
import {ForwardedRef, forwardRef} from "react";

const baseClassName = 'form-select';

interface IFormSelectProps {
  className?: string;
  defaultValue?: string;
  value?: string | null;
  label?: string;
  options: { value: string | null, label: string | null }[],
  disabled?: boolean;
  allowClear?: boolean;
  loading?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  error?: string;
}

export const FormSelect = forwardRef(({
  className = '',
  options,
  defaultValue = '',
  value = '',
  label = '',
  loading = false,
  disabled = false,
  allowClear = false,
  error = '',
  placeholder = '',
  onChange
}: IFormSelectProps, ref: ForwardedRef<any>): JSX. Element => {
  return (
    <div className="field-select">
      {label && <label className="field-select__label">{label}</label>}
      <Select
        ref={ref}
        defaultValue={defaultValue}
        value={value}
        className={`${baseClassName} ${className}`}
        onChange={onChange}
        options={options}
        loading={loading}
        allowClear={allowClear}
        placeholder={placeholder}
        status={error ? 'error' : undefined}
        disabled={disabled}
      />
      {error && <p className="field-input__error">{capitalize(error)}</p>}
    </div>
  );
});

FormSelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};