import { Tag } from "antd";
import PropTypes from "prop-types";
import { FaDollarSign } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES, ModalType, PricingPlan } from "../../../data-access";
import { storeModalsActions } from "../../../store/slices/modals";
import { FormButton } from "../../../ui";
import { PlanSkeleton } from "./plan-skeleton";
import "./plan.scss";
import { useUser } from "../../../hooks";

interface IPlanProps {
  id: number;
  title: PricingPlan;
  price: number;
  features: string[];
  periodName: string;
  isCurrent?: boolean;
  isProcessing?: boolean;
  priceWithDiscount?: string;
  onSelect: (planId: number) => void;
  isLoading?: boolean;
}

export const Plan = ({
  priceWithDiscount,
  isProcessing = false,
  periodName,
  id,
  title,
  price,
  features,
  isCurrent = false,
  isLoading = false,
  onSelect,
}: IPlanProps): JSX.Element => {
  const planFinalPrice = priceWithDiscount ? +priceWithDiscount : price;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useUser();

  const redirectToBilling = (): void => {
    navigate(APP_ROUTES.billing);
  };

  const showPurchaseLicences = (): void => {
    dispatch(
      storeModalsActions.open({
        type: ModalType.PurchaseLicences,
        props: { onSuccess: redirectToBilling },
      })
    );
  };

  const handleSwitchPlanClick = () => {
    onSelect(id);
    showPurchaseLicences();
  };

  return (
    <PlanSkeleton isLoading={isLoading || isProcessing}>
      <div className="plan">
        {currentUser?.license?.payment_status === "trial" &&
          title === "Top Rated Plus" && (
            <div className="trial-plan-label">Trial</div>
          )}
        <div className="column">
          {isCurrent && (
            <Tag className="plan-label" color="green">
              Current
            </Tag>
          )}
          <h2 className="plan-title">{title}</h2>
          <div className="plan-price">
            <span className="price">
              <FaDollarSign />
              {priceWithDiscount && <s>{price}</s>}
              {planFinalPrice}
            </span>{" "}
            {periodName}
          </div>
          <ul className="plan-features">
            {features.map((feature: string, index: number) => (
              <li key={`feature-${index}`} className="plan-feature">
                {feature}
              </li>
            ))}
          </ul>
        </div>
        {!isCurrent && (
          <FormButton
            className="plan-button"
            theme="blue"
            loading={isProcessing}
            disabled={isProcessing}
            onClick={handleSwitchPlanClick}
          >
            {`Switch to ${title}`}
          </FormButton>
        )}
        {isCurrent && (
          <FormButton
            className="plan-button"
            loading={isProcessing}
            disabled={isProcessing}
            onClick={showPurchaseLicences}
          >
            Add license
          </FormButton>
        )}
      </div>
    </PlanSkeleton>
  );
};

Plan.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceWithDiscount: PropTypes.string,
  periodName: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCurrent: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};
