import React, { FocusEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Tag, Tooltip } from "antd";
import { useForm, Controller, Control } from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import { settingsApi } from "../../../api";
import {
  APP_ROUTES,
  IGetSettingsResponse,
  IResponseError,
  ICrmSettings,
} from "../../../data-access";
import { useUser } from "../../../hooks";
import { RootState, store } from "../../../store";
import { storeSettingsActions } from "../../../store/slices/settings";
import { FormInput, FormSwitch, FormButton, FormSelect } from "../../../ui";
import { notify, handleFailedRequest } from "../../../util";
import "./crm-settings-form.scss";

interface ISimpleSwitchToggleSettingProps {
  name: keyof ICrmSettings;
  control: Control<ICrmSettings>;
  disabled?: boolean;
  label: string;
  tooltipText?: string;
}

const SimpleSwitchToggleSetting = ({
  name,
  control,
  disabled,
  label,
  tooltipText,
}: ISimpleSwitchToggleSettingProps): JSX.Element => {
  return (
    <div className="setting">
      <div className="setting__label">
        {label}
        <Tooltip title={tooltipText} color="#fff" key={name}>
          <FaInfoCircle />
        </Tooltip>
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <FormSwitch
            ref={ref}
            disabled={disabled}
            onChange={onChange}
            isChecked={!!value}
          />
        )}
      />
    </div>
  );
};

interface ICrmSettingsFormState {
  [key: string]: {
    title: string;
    value: boolean;
    expandable: boolean;
    tooltipText: string;
  };
}

interface ICrmSettingsFormProps {
  isFetching: boolean;
}

export const CrmSettingsForm = ({
  isFetching,
}: ICrmSettingsFormProps): JSX.Element => {
  const storeBasedSettings = store.getState().settings.crm as ICrmSettings;

  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);
  const isPipedriveConnected = useSelector(
    (state: RootState) => state.tools.is_pipedrive_connected
  );
  const { currentUser } = useUser();
  const dispatch = useDispatch();

  const isUserOnLitePlan =
    !!currentUser && currentUser?.license?.plan === "Lite";

  const [formSettings] = useState<ICrmSettingsFormState>({
    auto_create_company: {
      title: "Create the Contact's Company in the CRM automatically",
      value: storeBasedSettings.auto_create_company,
      expandable: false,
      tooltipText:
        "When adding a contact to your CRM from Upwork, Upwex will also create a company profile for the contact's current company in your CRM (if the company doesn't exist for the client on Upwork, then Upwex will create a company in the CRM with the contact's name).\n",
    },
    create_lead: {
      title: "Create lead or not",
      value: storeBasedSettings.create_lead,
      expandable: false,
      tooltipText:
        "Enable this if you want a lead to be created in your CRM when synchronizing Upwork chat.",
    },
    create_deal: {
      title: "Create deal or not ",
      value: storeBasedSettings.create_deal,
      expandable: true,
      tooltipText:
        "Enable this if you want a deal to be created in your CRM when synchronizing Upwork chat. Each new contract from the same client is a new deal.\n",
    },
    create_notes_for_job: {
      title: "Create notes about Job in Contact or Deal",
      value: storeBasedSettings.create_notes_for_job,
      expandable: false,
      tooltipText:
        "Enable creation of Job Details note. Includes: Job ID, Job Date, Country, City, Job Title, Job Description.\n",
    },
    create_notes_for_bid: {
      title: "Create notes about Bid in Contact or Deal",
      value: storeBasedSettings.create_notes_for_bid,
      expandable: false,
      tooltipText:
        "Enable creation of Bid Details note. Includes: Job ID, Bid Date, Proposed rate, and Cover letter.\n",
    },
    create_notes_for_all_messages: {
      title: "Create notes about All messages in Contact or Deal",
      value: storeBasedSettings.create_notes_for_all_messages,
      expandable: false,
      tooltipText:
        "Enables the creation of a note with the history of all messages.",
    },
  });

  const form = useForm<ICrmSettings>({
    mode: "all",
    defaultValues: {
      auto_create_company: storeBasedSettings.auto_create_company,
      create_lead: storeBasedSettings.create_lead,
      deal_name: storeBasedSettings.deal_name,
      create_deal: storeBasedSettings.create_deal,
      create_notes_for_job: storeBasedSettings.create_notes_for_job,
      create_notes_for_bid: storeBasedSettings.create_notes_for_bid,
      create_notes_for_all_messages:
        storeBasedSettings.create_notes_for_all_messages,
      create_all_notes_in_deal: storeBasedSettings.create_all_notes_in_deal,
    },
  });
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, dirtyFields, isValid },
  } = form;

  const handleFormSubmit = (formFields: ICrmSettings): void => {
    setIsProcessingForm(true);

    settingsApi
      .updateSettings(formFields)
      .then((response: IGetSettingsResponse) => {
        notify("success", "Settings have been successfully saved.");
        dispatch(storeSettingsActions.setCrmSettings({ ...response.data.crm }));
        reset(response.data.crm);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingForm(false));
  };

  const handleDealNameTagClick = (tag: string): void => {
    if (!isPipedriveConnected) {
      return;
    }
    // replace subsequent spaces with one space
    const currentValue = getValues("deal_name") ?? "";
    const modifiedFieldValue = `${currentValue} ${tag}`
      .replace(/  +/g, " ")
      .trim();
    setValue("deal_name", modifiedFieldValue);
  };

  const isCreateDealEnabled = watch("create_deal");
  const isFormBeenModified = Object.keys(dirtyFields).length > 0;
  const isCRMElementDisabled = isProcessingForm || !isPipedriveConnected;

  return (
    <form className="settings-form" onSubmit={handleSubmit(handleFormSubmit)}>
      {!isFetching && isPipedriveConnected && !isUserOnLitePlan && (
        <div className="crm-settings column">
          {Object.keys(formSettings).map((key: string) => {
            const settingKey = key as keyof ICrmSettings;

            return (
              <div className="column" key={settingKey}>
                {!formSettings[settingKey]?.expandable && (
                  <SimpleSwitchToggleSetting
                    control={control}
                    disabled={isCRMElementDisabled}
                    label={formSettings[settingKey].title}
                    name={settingKey}
                    tooltipText={formSettings[settingKey].tooltipText}
                  />
                )}
                {formSettings[settingKey]?.expandable && (
                  <div className="expandable-setting">
                    <SimpleSwitchToggleSetting
                      control={control}
                      disabled={isCRMElementDisabled}
                      label={formSettings[settingKey].title}
                      name={settingKey}
                      tooltipText={formSettings[settingKey].tooltipText}
                    />
                    {isCreateDealEnabled && (
                      <div className="create-deal-section">
                        <h2 className="section-title">Create Deal name *</h2>
                        <FormInput
                          {...register("deal_name", {
                            required: "This field is required",
                          })}
                          value={watch("deal_name")}
                          placeholder="New deal with #contact of #company"
                          error={errors?.deal_name?.message}
                          disabled={isCRMElementDisabled}
                          onChange={(e: FocusEvent<HTMLInputElement>) =>
                            setValue("deal_name", e?.target?.value, {
                              shouldDirty: true,
                              shouldValidate: true,
                              shouldTouch: true,
                            })
                          }
                        />
                        <p className="note">
                          Change the default template for your new deal &nbsp;{" "}
                          <Tag
                            color="green"
                            onClick={() => handleDealNameTagClick("#contact")}
                          >
                            #contact
                          </Tag>
                          , &nbsp;
                          <Tag
                            color="green"
                            onClick={() => handleDealNameTagClick("#firstname")}
                          >
                            #firstname
                          </Tag>
                          , &nbsp;
                          <Tag
                            color="green"
                            onClick={() => handleDealNameTagClick("#lastname")}
                          >
                            #lastname
                          </Tag>
                          &nbsp; and &nbsp;
                          <Tag
                            color="green"
                            onClick={() => handleDealNameTagClick("#company")}
                          >
                            #company
                          </Tag>
                          &nbsp; will be replaced with the actual deal's
                          contacts and company name.
                        </p>
                        <div className="expandable-setting__inner-switch">
                          <SimpleSwitchToggleSetting
                            control={control}
                            disabled={isCRMElementDisabled}
                            label="Create all notes about Job in Deal"
                            name="create_all_notes_in_deal"
                            tooltipText="All notes will be created in the deal: Job Details, Proposal Details, and All messages. By default, all this information is created in the contact."
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {isUserOnLitePlan && (
        <div className="no-crm-settings">
          This functionality is not available for <b>Lite</b> plan. Please
          upgrade your{" "}
          <NavLink className="link" to={APP_ROUTES.plans}>
            plan
          </NavLink>{" "}
          to unlock it.
        </div>
      )}
      {!isFetching && !isPipedriveConnected && !isUserOnLitePlan && (
        <div className="no-crm-settings">
          Please connect your{" "}
          <NavLink to={APP_ROUTES.toolsAndApps}>&nbsp;CRM&nbsp;</NavLink> to
          view the settings.
        </div>
      )}
      <FormButton
        className="form-button"
        htmlType="submit"
        loading={isProcessingForm}
        disabled={!isFormBeenModified || !isValid || isProcessingForm}
      >
        Save
      </FormButton>
    </form>
  );
};

export default CrmSettingsForm;
