import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersApi } from "../../api";
import {
  AnalyticsPeriod,
  IAnalyticsResponse,
  IResponseError,
  ITeamMemberRecord,
  ITeamMembersResponse,
  ProfileFilterCriteriaType,
  IProfilesGeneralStatistics,
  IProfilesAnalyticsResponse,
} from "../../data-access";
import { useUser } from "../../hooks";
import { RootState } from "../../store";
import { storeAnalyticsActions } from "../../store/slices/analytics";
import { storeTeamActions } from "../../store/slices/team";
import {
  FormSelect,
  Panel,
  BarChart,
  DateRangePicker,
  FormButton,
} from "../../ui";
import { capitalize, handleFailedRequest } from "../../util";
import { GeneralStatistics } from "./general-statistics";
import { ProfileStatistics } from "./profile-statistics";
import "./analytics.scss";
import dayjs, { Dayjs } from "dayjs";
import { FaFileCsv } from "react-icons/fa";
import { RangePickerProps } from "antd/es/date-picker";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Upwork profile", key: "upwork_profile" },
  { label: "Upwex user", key: "upwex_user" },
  { label: "Used Connections", key: "connections" },
  { label: "Invites", key: "invites" },
  { label: "Direct Messages", key: "direct_messages" },
  { label: "Proposals", key: "proposals" },
  { label: "Reply", key: "reply" },
  { label: "Reply Rate", key: "reply_rate" },
];

const periodFilter = [
  { value: "current_week", label: "Current Week" },
  { value: "last_week", label: "Last Week" },
  { value: "current_month", label: "Current Month" },
  { value: "last_month", label: "Last Month" },
  { value: "last_3_months", label: "Last 3 Months" },
  { value: "last_year", label: " Last Year" },
];

const profileStatsFilters = [
  {
    name: "current_week",
    label: "Current Week",
  },
  {
    name: "last_week",
    label: "Last Week",
  },
  {
    name: "current_month",
    label: "Current Month",
  },
  {
    name: "last_month",
    label: "Last Month",
  },
  {
    name: "last_3_months",
    label: "3 Months",
  },
  {
    name: "last_6_months",
    label: "6 Months",
  },
  {
    name: "current_year",
    label: "Current Year",
  },
  {
    name: "",
    label: "All Time",
  },
];

const options = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const baseClass = "analytics";

export const Analytics = (): JSX.Element => {
  const { currentUser } = useUser();

  const teamMembers = useSelector((state: RootState) => state.team.members);
  const filterPeriod = useSelector(
    (state: RootState) => state.analytics.filter.period
  );
  const filterUserId = useSelector(
    (state: RootState) => state.analytics.filter.userId
  );

  const temMemberFilterOptions = useMemo(
    () =>
      teamMembers.map((member: ITeamMemberRecord) => ({
        value: member.id,
        label:
          member.full_name ??
          capitalize(member.email.substring(0, member.email.indexOf("@"))),
      })),
    [teamMembers]
  );

  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);
  const [isFetchingMembers, setIsFetchingMembers] = useState<boolean>(false);
  const [isFetchingJobScoreStats, setIsFetchingJobScoreStats] =
    useState<boolean>(true);
  const [currentUserFilter, setCurrentUserFilter] = useState<string>(
    filterUserId ?? String(currentUser?.id)
  );
  const [currentPeriodFilter, setCurrentPeriodFilter] = useState<string>(
    filterPeriod || periodFilter[0]?.value
  );
  const [analytics, setAnalytics] = useState<IAnalyticsResponse>({
    total: {
      ai_answers: 0,
      ai_proposals: 0,
      bids: 0,
      opened_jobs: 0,
      replies: 0,
      reply_rate: 0,
      sync_with_crms: 0,
    },
  } as IAnalyticsResponse);
  const [currentProfileStatsCriteria, setCurrentProfileStatsCriteria] =
    useState<any>(profileStatsFilters[0].name as ProfileFilterCriteriaType);
  const [profilesStats, setProfilesStats] =
    useState<IProfilesGeneralStatistics>({} as IProfilesGeneralStatistics);
  const [upworkCSVData, setUpworkCSVData] = useState<any[]>([]);

  const [rangeDate, setRangeDate] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [compareDate, setCompareDate] = useState<string>("");

  const dispatch = useDispatch();

  const handlePeriodFilterChange = (period: string): void => {
    setCurrentPeriodFilter(period);
    dispatch(storeAnalyticsActions.setPeriod({ period }));
  };

  const handleUsersFilterChange = (userId: string): void => {
    setCurrentUserFilter(userId);
    dispatch(storeAnalyticsActions.setUserId({ userId }));
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current >= dayjs().endOf("day");
  };

  useEffect(() => {
    if (teamMembers.length === 0) {
      setIsFetchingMembers(true);
      usersApi
        .getTeamMembers()
        .then((response: ITeamMembersResponse) => {
          dispatch(storeTeamActions.setMembers(response.data));
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text);
        })
        .finally(() => setIsFetchingMembers(false));
    }

    setTimeout(() => {
      setIsFetchingJobScoreStats(false);
    }, 1500);
  }, [dispatch, teamMembers.length]);

  useEffect(() => {
    setIsProcessingRequest(true);

    usersApi
      .getUserAnalytics({
        id: currentUserFilter,
        period: currentPeriodFilter as AnalyticsPeriod,
      })
      .then((response: { data: IAnalyticsResponse }) => {
        setAnalytics(response.data);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingRequest(false));
  }, [currentPeriodFilter, currentUserFilter]);

  useEffect(() => {
    // if (currentProfileStatsCriteria) {
    setIsFetchingJobScoreStats(true);
    usersApi
      .getAnalyticsForProfiles(currentProfileStatsCriteria)
      .then((response: IProfilesAnalyticsResponse) => {
        setProfilesStats(response.data);
        getCsvData(response.data);
      })
      .catch((error: IResponseError) =>
        handleFailedRequest(error.status, error.text)
      )
      .finally(() => setIsFetchingJobScoreStats(false));
    // }
  }, [currentProfileStatsCriteria]);

  useEffect(() => {
    if (rangeDate[0] && rangeDate[1]) {
      setIsFetchingJobScoreStats(true);
      const paramsString = `date_from=${rangeDate[0].format(
        "YYYY-MM-DD"
      )}&date_to=${rangeDate[1].format("YYYY-MM-DD")}`;
      usersApi
        .getAnalyticsForProfilesByDate(paramsString)
        .then((response: IProfilesAnalyticsResponse) => {
          setProfilesStats(response.data);
          getCsvData(response.data);
        })
        .catch((error: IResponseError) =>
          handleFailedRequest(error.status, error.text)
        )
        .finally(() => setIsFetchingJobScoreStats(false));
    }
  }, [rangeDate]);

  const handleProfileStatsFilterClick = (
    criteria: ProfileFilterCriteriaType
  ): void => {
    if (isFetchingJobScoreStats) return;

    setCurrentProfileStatsCriteria(criteria);
    setRangeDate([null, null]);
  };

  const handleFilterChange = (value: string): void => {
    setCompareDate(value);
  };

  const getCsvData = (data: any): void => {
    const items =
      data.profiles.map((profile: any) => {
        return {
          upwork_profile: profile.upwork_user.username,
          upwex_user: profile.email,
          connections: profile.used_connects,
          invites: profile.invites,
          direct_messages: profile.direct_messages,
          proposals: profile.bids,
          reply: profile.replies,
          reply_rate: profile.reply_rate,
        };
      }) || [];
    setUpworkCSVData(items);
  };

  const handleProfileStatsDatesChange = (
    data: [Dayjs | null, Dayjs | null]
  ) => {
    if (data && data[0] && data[1]) {
      setRangeDate(data);
      setCurrentProfileStatsCriteria("current_week");
    } else {
      setCurrentProfileStatsCriteria("current_week");
      setRangeDate([null, null]);
    }
  };

  return (
    <div className={baseClass}>
      <Panel
        title="Upwork Profile Stats"
        className={`profile-stats-panel ${
          isFetchingJobScoreStats ? "is-loading" : ""
        }`}
        nodeInTitleRow={
          <div className={`${baseClass}__filters-content`}>
            <ul className={`${baseClass}__profile-stats-filters`}>
              {profileStatsFilters.map((filter) => (
                <li
                  key={filter.name}
                  className={`${baseClass}__profile-stats-filters ${
                    currentProfileStatsCriteria === filter.name ? "active" : ""
                  }`}
                  onClick={() =>
                    handleProfileStatsFilterClick(
                      filter.name as ProfileFilterCriteriaType
                    )
                  }
                >
                  {filter.label}
                </li>
              ))}
            </ul>
            {/* <DateRangePicker
              defaultValue={rangeDate}
              disabledDate={disabledDate}
              onChange={(event) => handleProfileStatsDatesChange(event)}
            />
            {profilesStats?.profiles?.length > 0 ? (
              <CSVLink
                className="export-button"
                data={upworkCSVData}
                headers={headers}
                filename={"upwex_upwork_profile_statistics.csv"}
              >
                Export CSV
              </CSVLink>
            ) : (
              <FormButton className="invite-button" disabled>
                Export CSV
              </FormButton>
            )} */}
          </div>
        }
      >
        <ProfileStatistics
          isLoading={isFetchingJobScoreStats}
          data={profilesStats}
          filterPeriod={currentProfileStatsCriteria}
          rangeDate={rangeDate}
        />
      </Panel>
      <Panel
        title="User Statistics"
        className={`${baseClass}-panel`}
        nodeInTitleRow={
          <div className={`${baseClass}__filters`}>
            <FormSelect
              value={currentPeriodFilter}
              disabled={isProcessingRequest || isFetchingMembers}
              options={periodFilter}
              onChange={handlePeriodFilterChange}
            />
            <FormSelect
              value={currentUserFilter}
              disabled={isProcessingRequest}
              placeholder="Please select a user"
              options={[
                {
                  label: "All",
                  value: "",
                },
                ...temMemberFilterOptions,
              ]}
              onChange={handleUsersFilterChange}
            />
          </div>
        }
      >
        <GeneralStatistics
          isLoading={isProcessingRequest}
          stats={analytics?.total}
        />
      </Panel>
      <Panel className="chart-panel" isLoading={isProcessingRequest}>
        {!isProcessingRequest && (
          <BarChart
            isLoading={isProcessingRequest}
            labels={analytics?.graph?.labels}
            datasets={[
              {
                label: "Open Job",
                data: analytics?.graph?.datasets?.opened_jobs || [],
                backgroundColor: "#57cc99",
              },
              {
                label: "Bid",
                data: analytics?.graph?.datasets?.bids || [],
                backgroundColor: "#80ed99",
              },
              {
                label: "Reply",
                data: analytics?.graph?.datasets?.replies || [],
                backgroundColor: "#c7f9cc",
              },
            ]}
          />
        )}
      </Panel>
    </div>
  );
};

export default Analytics;
