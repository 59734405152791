import PropTypes from 'prop-types';
import { ReactElement } from 'react';
import './extension-info.scss';

interface IExtensionInfoProps {
  title?: string;
  children?: ReactElement | null;
}

export const ExtensionInfo = ({ title = 'Upwork AI Proposal & Sync with Pipedrive', children = null }: IExtensionInfoProps): JSX.Element => {
  return (
    <div className="extension-info">
      <div className="extension-info__container">
        <h1 className="extension-info__motto">{title}</h1>
        {!children && (
          <p className="extension-info__description">
            Upwex.io is a browser extension that uses AI to improve results for freelancers and companies on Upwork.
            It offers features such as job post analysis, proposal generation, question answering, Pipedrive CRM sync, and more.
            Save time, increase closed deals, and improve your reputation with Upwex.io.
          </p>
        )}
        {children && (
          <div className="extension-info__content">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

ExtensionInfo.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element
};