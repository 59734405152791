import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { APP_ROUTES } from '../../data-access';
import { storeAuthActions } from '../../store/slices/auth';
import { cookie, encryption, logoutUser } from '../../util';

export const ExtensionAuth = (): null => {
  const queryParams = new URLSearchParams(window.location.search);
  const authFromExtension = queryParams.get('extAuth');
  const redirectUrl = queryParams.get('redirectUrl');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  if (authFromExtension && redirectUrl) {
    const encryptedUserData = cookie.get('extFlag');

    if (!encryptedUserData) {
      logoutUser();
    } else {
      const userToAuth = JSON.parse(encryption.decryptFromHash(encryptedUserData));
      dispatch(storeAuthActions.setUserData({
        access_token: userToAuth.access_token,
        refresh_token: userToAuth.refresh_token,
        user: userToAuth.user,
      }));
      cookie.set('exSM', encryption.generateHash('authorized'));
      navigate(`/${redirectUrl}`)
    }
  } else {
    navigate(APP_ROUTES.login);
  }

  return null;
}