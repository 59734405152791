import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout } from "..";
import { APP_ROUTES } from "../../data-access";
import { useUser } from "../../hooks";

interface IPrivateRouteProps {
  isForAdminOnly?: boolean;
  children: JSX.Element;
}

export const PrivateRoute = ({
  isForAdminOnly = false,
  children,
}: IPrivateRouteProps): JSX.Element => {
  const { currentUser, hasAdminRights } = useUser();

  const restrictAccessCondition = isForAdminOnly
    ? !hasAdminRights
    : currentUser === null;

  if (restrictAccessCondition) {
    return <Navigate to={APP_ROUTES.login} />;
  }

  return <Layout>{children}</Layout>;
};

PrivateRoute.propTypes = {
  isForAdminOnly: PropTypes.bool,
  children: PropTypes.element.isRequired,
};
