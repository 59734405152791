import React, { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaRegThumbsUp } from 'react-icons/fa';
import * as yup from 'yup';
import { IRequestedFeature, RequestFeatureFilterCriteria } from '../../data-access';
import { FormButton, FormInput, FormSelect, FormTextarea, NoRecordsPanel, Spinner } from '../../ui';
import './feature-request.scss';

const baseClass = 'feature-request';

interface IFeatureRequestFormData {
  title: string;
  description: string;
}

const validationScheme = yup.object({
  title: yup.string().required(),
  description: yup.string().required()
}).required();

const features = [
  { title: 'Feature', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 10 },
  { title: 'New Feature title', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 42 },
  { title: 'Create some sorting', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 35 },
  { title: 'Create filtering', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 65 },
  { title: 'Data seed', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 47 },
  { title: 'Check user status', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 67 },
  { title: 'Something else', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 160 },
  { title: 'New cool features', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 78 },
  { title: 'Feature title will be here', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 22 },
  { title: 'Feature specific title', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.', status: 'In Progress', votes: 11 },
];

const initialFilterOptions = [
  { label: 'Newest Features', value: 'recent' },
  { label: 'Popular Features', value: 'popular' },
];

export const FeatureRequest = (): JSX.Element => {
  const form = useForm<IFeatureRequestFormData>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      description: ''
    },
    resolver: yupResolver(validationScheme),
  });
  const { control, handleSubmit, setValue, formState: { errors, dirtyFields } } = form;

  const initialFeaturesRef = useRef<IRequestedFeature[]>(features);
  const [featuresList, setFeaturesList] = useState<IRequestedFeature[]>(initialFeaturesRef.current);
  const [searchCriteria, setSearchCriteria] = useState<string | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [filterCriteria, setFilterCriteria] = useState<RequestFeatureFilterCriteria>(RequestFeatureFilterCriteria.recent);

  const isFormValid = Object.keys(dirtyFields).length > 0 && form.formState.isValid;

  const handleFormSubmit = (formFields: IFeatureRequestFormData): void => {
    console.log('fields = ', formFields);
  }

  useEffect(() => {
    let delayDebounceFn: any;

    if (searchCriteria !== undefined) {
      setIsProcessing(true);

      delayDebounceFn = setTimeout(() => {
        const lowerCasedSearchCriteria = searchCriteria.trim().toLowerCase();

        const matchingFeatures = lowerCasedSearchCriteria
          ? initialFeaturesRef.current.filter((feature: IRequestedFeature) => {
            return feature.title.toLowerCase().includes(lowerCasedSearchCriteria) || feature.description.toLowerCase().includes(lowerCasedSearchCriteria);
          })
          : initialFeaturesRef.current;

        setFeaturesList(matchingFeatures);
        setIsProcessing(false);
      }, 1000);
    }

    return () => delayDebounceFn && clearTimeout(delayDebounceFn);
  }, [searchCriteria]);

  useEffect(() => {
    if (filterCriteria === 'popular') {
      const mostPopularFeatures = [...initialFeaturesRef.current]
        .sort((a: IRequestedFeature, b: IRequestedFeature) => { return b.votes - a.votes })
        .slice(0, 5);
      setFeaturesList(mostPopularFeatures);
    } else {
      setFeaturesList(initialFeaturesRef.current);
    }
  }, [filterCriteria]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__form-wrapper`}>
        <h1 className={`${baseClass}__main-title`}>Create a Post</h1>
        <span className={`${baseClass}__description`}>
          Let us know how we can improve the world's most popular music and podcast listening service.
        </span>
        <form className={`${baseClass}__form`} onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller
            control={control}
            name="title"
            render={({ field: { name, onBlur, value, ref } }) => (
              <FormInput
                ref={ref}
                placeholder="Please enter feature title"
                label="Title"
                error={errors?.title?.message}
                name={name}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={(e: FocusEvent<HTMLInputElement>) => setValue('title', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { name, onBlur, value, ref } }) => (
              <FormTextarea
                ref={ref}
                placeholder="Please enter feature description"
                label="Description (250 characters max)"
                error={errors?.description?.message}
                name={name}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={(e: FocusEvent<HTMLTextAreaElement>) => setValue('description', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
              />
            )}
          />
          <div className={`${baseClass}__button-wrap`}>
            <FormButton
              className={`${baseClass}__button`}
              type="primary"
              htmlType="submit"
              loading={false}
              disabled={!isFormValid}
            >
              Add post
            </FormButton>
          </div>
        </form>
      </div>
      <div className={`${baseClass}__features-list`}>
        <div className={`${baseClass}__search-and-filter`}>
          <FormSelect
            value={filterCriteria}
            disabled={isProcessing || !!searchCriteria}
            options={initialFilterOptions}
            onChange={(value: string) => setFilterCriteria(value as RequestFeatureFilterCriteria)}
          />
          <FormInput
            placeholder="Please enter search criteria..."
            name="search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchCriteria(e.target.value)}
          />
        </div>
        {isProcessing && <Spinner />}
        {!isProcessing && featuresList.map((feature: IRequestedFeature, index: number) => (
          <div key={feature.title + index} className={`${baseClass}__feature`}>
            <div className={`${baseClass}__vote`}>
              <FaRegThumbsUp />
              {feature.votes}
              </div>
            <div className={`${baseClass}__feature-content`}>
              <div className="row">
                <h3 className={`${baseClass}__feature-title`}>{feature.title}</h3>
                <div className={`${baseClass}__feature-status`}>
                  <span className={`${baseClass}__feature-status-indicator`} />
                  {feature.status}
                </div>
              </div>
              <p className={`${baseClass}__feature-description`}>{feature.description}</p>
            </div>
          </div>
        ))}
        {!isProcessing && featuresList.length === 0 && (
          <NoRecordsPanel>No records matching criteria found</NoRecordsPanel>
        )}
      </div>
    </div>
  );
}