import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toolsApi } from "../../api";
import { APP_ROUTES, IAuthResponse, IResponseError } from "../../data-access";
import { storeAuthActions } from "../../store/slices/auth";
import {
  cookie,
  encryption,
  handleFailedRequest,
  updateExtensionCookies,
} from "../../util";

export const PipedriveAuth = (): null => {
  const queryParams = new URLSearchParams(window.location.search);

  const code = queryParams.get("code") ?? "";
  const state = queryParams.get("state") ?? undefined;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!code) {
    navigate(APP_ROUTES.dashboard);
  }

  const authorizeUser = (authData: IAuthResponse): void => {
    cookie.set("exSM", encryption.generateHash("authorized"));

    const authorizeData = {
      access_token: authData.access_token,
      refresh_token: authData.refresh_token,
      user: authData.user,
    };

    updateExtensionCookies(authorizeData);

    dispatch(storeAuthActions.setUserData(authorizeData));
  };

  useEffect(() => {
    toolsApi
      .authorizePipedrive({ access_code: code, state })
      // for authorized user - response is empty string
      // for unauthorized user - response is tokens and user details
      .then((authResponse: IAuthResponse | string) => {
        if (authResponse !== "") {
          authorizeUser(authResponse as IAuthResponse);
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => navigate(APP_ROUTES.toolsAndApps));
  }, []);

  return null;
};
