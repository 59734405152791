import PropTypes from 'prop-types';
import { ReactNode } from 'react';
import { Modal } from 'antd';
import './modal-dialog.scss';

interface IModalDialogProps {
  title?: string | ReactNode;
  isOpen?: boolean;
  children: string | ReactNode;
  onConfirm?: (data: any) => void,
  onCancel: () => void,
  className?: string;
  enableActionButtons?: boolean;
  confirmText?: string;
  confirmLoading?: boolean;
  footer?: ReactNode[];
  closeIcon?: ReactNode;
  closable?: boolean;
}

export const ModalDialog = ({ enableActionButtons = false, closable = true, closeIcon, confirmLoading = false, confirmText = 'Ok', className = '', isOpen = false, title = 'Action', onCancel, onConfirm, children, footer = [] }: IModalDialogProps): JSX.Element => {
  return (
    <Modal
      title={title}
      centered
      open={isOpen}
      closeIcon={closeIcon}
      onOk={onConfirm}
      okText={confirmText}
      onCancel={onCancel}
      className={className}
      confirmLoading={confirmLoading}
      footer={footer.length > 0 ? footer : !enableActionButtons ? null : undefined}
      cancelButtonProps={{ disabled: confirmLoading }}
      closable={closable}
    >
      {children}
    </Modal>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isOpen: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  okText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  enableActionButtons: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  closeIcon: PropTypes.node,
  closable: PropTypes.bool,
};