import React from "react";
import PropTypes from "prop-types";
import "./job-searcher-statistics.scss";
import { PlanCard } from "../../billing/current-plan/plan-card";
import { FaBriefcase, FaPercent } from "react-icons/fa";

interface IGeneralStatisticsProps {
  stats: any;
  isLoading?: boolean;
}

const baseClass = "job-searcher-stats";

export const JobSearcherStatistics = ({
  stats,
  isLoading = false,
}: IGeneralStatisticsProps): JSX.Element => {
  const getCountDataByName = (
    type: string,
    name: string,
    count: string
  ): any => {
    return count
      ? {
          name,
          subscriptions: count,
          type,
        }
      : ({} as any);
  };

  return (
    <div className={`${baseClass}__content`}>
      <PlanCard
        icon={<FaBriefcase className={`${baseClass}__default`} />}
        title="Open jobs"
        value={`${stats.open_jobs_count || 0}`}
        isLoading={isLoading}
      />
      <PlanCard
        icon={<FaBriefcase className={`${baseClass}__success`} />}
        title="Matched Jobs"
        value={`${stats.approved_jobs_count || 0}`}
        isLoading={isLoading}
      />
      <PlanCard
        icon={<FaBriefcase className={`${baseClass}__failed`} />}
        title="Skipped jobs"
        value={`${stats.skipped_jobs_count || 0}`}
        isLoading={isLoading}
      />
      <PlanCard
        icon={<FaPercent className={`${baseClass}__middle`} />}
        title="Matched percent"
        value={`${stats.approved_percent || 0}%`}
        isLoading={isLoading}
      />
    </div>
  );
};

JobSearcherStatistics.propTypes = {
  stats: PropTypes.shape({
    open_jobs_count: PropTypes.number,
    approved_jobs_count: PropTypes.number,
    skipped_jobs_count: PropTypes.number,
    approved_percent: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool,
};
