import PropTypes from 'prop-types';
import './avatar.scss';

interface IAvatarProps {
  imageSrc?: string;
  imageAlt?: string;
  size?: 'regular' | 'small';
}

export const Avatar = ({ imageSrc = '', imageAlt = 'Upwex.io', size = 'regular' }: IAvatarProps): JSX.Element => {
  const avatarSrc = imageSrc ? imageSrc : '../../../avatar.svg';
  return (
    <div className={`avatar ${size}`}>
      <img src={avatarSrc} alt={imageAlt} />
    </div>
  );
};

Avatar.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  size: PropTypes.oneOf(['regular', 'small']),
};