import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './back-button.scss';

interface IBackButtonProps {
  to: string;
  children: string;
}

export const BackButton = ({ to, children }: IBackButtonProps): JSX.Element => (
  <Link className="back-button" to={to}  state={{ allowedTransition: true }}>
    <FaArrowLeft /> {children}
  </Link>
);

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};