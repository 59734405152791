import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES, PaymentMethod } from '../../../data-access';
import { Panel, FormButton } from '../../../ui';
import { notify } from '../../../util';
import './choose-payment-method.scss';

interface IChoosePaymentMethodFormData {
  paymentMethod: PaymentMethod;
}

export const ChoosePaymentMethod = (): JSX.Element => {
  const navigate = useNavigate();

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethod>(PaymentMethod.SEPA);

  const form = useForm<IChoosePaymentMethodFormData>({
    mode: 'onBlur',
    defaultValues: {
      paymentMethod: currentPaymentMethod
    },
  });
  const { handleSubmit, setValue } = form;

  const handleFormSubmit = (formFields: IChoosePaymentMethodFormData): void => {
    console.log(formFields);
    notify('success', `Payment method has been successfully changed to ${currentPaymentMethod}`);
  }

  const handleCancelClick = (): void => {
    navigate(APP_ROUTES.billing, { state: { allowedTransition: true } });
  }

  const handlePaymentMethodChange = (e: RadioChangeEvent): void => {
    const { value: fieldValue } = e.target;
    setValue('paymentMethod', fieldValue, { shouldDirty: true, shouldValidate: true });
    setCurrentPaymentMethod(fieldValue);
  }

  return (
    <Panel title="Select a payment method" className="select-payment-method">
      <>
        <span className="select-payment-method__note">Choose a payment method of your liking.</span>
        <form className="select-payment-method__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Radio.Group className="select-payment-method__methods" onChange={handlePaymentMethodChange} name="paymentMethod" value={currentPaymentMethod}>
            <Radio value={PaymentMethod.SEPA}>{PaymentMethod.SEPA}</Radio>
            <Radio value={PaymentMethod.CREDIT}>{PaymentMethod.CREDIT}</Radio>
          </Radio.Group>
          <div className="select-payment-method__buttons-panel">
            <FormButton onClick={handleCancelClick}>Cancel</FormButton>
            <FormButton htmlType="submit">Save</FormButton>
          </div>
        </form>
      </>
    </Panel>
  );
}