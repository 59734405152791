import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { billingApi, usersApi } from "../../api";
import { APP_ROUTES } from "../../data-access";
import { storeAuthActions } from "../../store/slices/auth";
import { IPlan, IResponseError, IUser, PricingPlan } from "../../data-access";
import { RootState } from "../../store";
import { storeBillingActions } from "../../store/slices/billing";
import { BackButton } from "../../ui";
import { handleFailedRequest, updateExtensionCookies } from "../../util";
import { Plan } from "./plan";
import "./plans.scss";

export const Plans = (): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [planIdInProgressToBeCurrent, setPlanIdInProgressToBeCurrent] =
    useState<number | null>(null);

  const plans = useSelector((state: RootState) => state.billing.plans).filter(
    (plan) => !plan.is_archived
  );
  const currentPlan = useSelector(
    (state: RootState) => state.billing.currentPlan
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setIsProcessing(true);
    billingApi
      .getPlans()
      .then((response: { data: IPlan[] }) => {
        dispatch(
          storeBillingActions.update({
            plans: response.data.filter((plan) => !plan.is_trial),
            currentPlan: response.data.find(
              (plan: IPlan) => plan.is_selected && !plan.is_trial
            ),
          })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessing(false));
  }, []);

  const currentPlanAsJSON = JSON.stringify(currentPlan);
  useEffect(() => {
    usersApi.getMe().then((response: { data: IUser }) => {
      const userData = { user: response.data };
      dispatch(storeAuthActions.setUserData(userData));
      updateExtensionCookies(userData);
    });
  }, [currentPlanAsJSON]);

  const handlePlanSelection = (id: number): void => {
    setIsProcessing(true);
    setPlanIdInProgressToBeCurrent(id);
    billingApi
      .selectPlan(id)
      .then((response: { data: IPlan[] }) => {
        const actualPlans = response.data;
        const targetPlanIdx = actualPlans.findIndex(
          (plan: IPlan) => plan.is_selected
        );

        dispatch(
          storeBillingActions.update({
            plans: actualPlans.filter((plan) => !plan.is_trial),
            currentPlan: actualPlans[targetPlanIdx],
          })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
        setPlanIdInProgressToBeCurrent(null);
      });
  };

  return (
    <div className="plans">
      <h1 className="title">Pricing Plans</h1>
      <p className="section-description">
        Choose the best plan to fit your needs.
      </p>
      <div className="row">
        {plans.map((plan: IPlan) => (
          <Plan
            key={plan.id}
            id={plan.id}
            title={plan.name as PricingPlan}
            periodName={plan.period_name}
            price={+plan.price}
            priceWithDiscount={plan?.price_discounted}
            features={plan.description}
            isCurrent={plan.is_selected}
            isLoading={isProcessing}
            isProcessing={planIdInProgressToBeCurrent === plan.id}
            onSelect={handlePlanSelection}
          />
        ))}
      </div>
      <BackButton to={APP_ROUTES.billing}>Back to Billing</BackButton>
    </div>
  );
};
