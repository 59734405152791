import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBookmark, FaCertificate, FaPercent } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { billingApi } from "../../../api";
import {
  APP_ROUTES,
  ILicence,
  IPlan,
  IResponseError,
  ModalType,
} from "../../../data-access";
import { useUser } from "../../../hooks";
import { RootState } from "../../../store";
import { storeBillingActions } from "../../../store/slices/billing";
import { storeModalsActions } from "../../../store/slices/modals";
import {
  convertUTCDateToLocalDate,
  handleFailedRequest,
  notify,
} from "../../../util";
import { PlanCard } from "./plan-card";
import "./current-plan.scss";
import moment from "moment";

export const CurrentPlan = (): JSX.Element => {
  const { currentUser } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentPlan = useSelector(
    (state: RootState) => state.billing.currentPlan
  );
  const licences = useSelector((state: RootState) => state.billing.licences);
  const discount =
    useSelector((state: RootState) => state.billing.discount) ??
    currentUser?.promocode;
  const [isFetching, setIsFetching] = useState<boolean>(!currentPlan);

  useEffect(() => {
    setIsFetching(true);
    billingApi
      .getPlans()
      .then((response: { data: IPlan[] }) => {
        const currentPlan = response.data.find(
          (plan: IPlan) => plan.is_selected
        );
        dispatch(
          storeBillingActions.update({
            plans: response.data,
            currentPlan,
          })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsFetching(false));
  }, [dispatch]);

  const handlePurchaseConfirmation = (): void => {
    setIsFetching(true);
    billingApi
      .createBillingLicence()
      .then((response: { data: ILicence }) => {
        dispatch(
          storeBillingActions.update({
            licences: [response.data, ...licences],
          })
        );
        handlePay(response.data.id);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handlePay = (id: string): void => {
    setIsFetching(true);
    billingApi
      .getPaymentLink(id)
      .then((response: { data: { payment_url: string } }) => {
        if (response.data.payment_url) {
          window.location.href = response.data.payment_url;
        } else {
          notify(
            "error",
            "Payment Error: Please contact our support team via the chat."
          );
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const showHowChangePlan = (): void => {
    navigate(APP_ROUTES.plans);
  };

  const showDiscounts = (): void => {
    dispatch(storeModalsActions.open({ type: ModalType.Discounts }));
  };

  const getPlanLabel = () => {
    if (currentUser?.license?.payment_status === "trial") {
      return currentPlan?.name === "Top Rated Plus" ||
        currentUser?.license?.plan === "Trial" ? (
        <div className="current-plan-label">Trial</div>
      ) : (
        <div className="current-plan-label">Top Rated Plus Trial</div>
      );
    } else {
      return <FaBookmark />;
    }
  };

  return (
    <div className="plan-cards">
      <PlanCard
        icon={getPlanLabel()}
        title="Plan"
        value={currentPlan?.name || "None"}
        buttonText="Switch"
        isLoading={isFetching}
        onClick={showHowChangePlan}
      />
      <PlanCard
        icon={<FaCertificate />}
        title="Active licences"
        plan={currentPlan}
        value={Number(currentUser?.active_licenses_count)}
        buttonText="Purchase"
        isLoading={isFetching}
        onClick={handlePurchaseConfirmation}
      />
      <PlanCard
        icon={<FaPercent />}
        title="Discount"
        value={
          discount?.discount_amount ? (
            <div className="discount">
              <span className="value">{discount.code}</span>
              <span className="caption">
                ({discount.discount_amount}% discount, expires:{" "}
                {moment(discount.expire_at).format("DD MMMM YYYY")})
              </span>
            </div>
          ) : (
            "None"
          )
        }
        buttonText={discount ? "Change" : "Apply"}
        isLoading={isFetching}
        onClick={showDiscounts}
      />
    </div>
  );
};
