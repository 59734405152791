import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  APP_ROUTES,
  IProfilesGeneralStatistics,
  ProfileFilterCriteriaType,
} from "../../../data-access";
import { Avatar, DataTable } from "../../../ui";
import { paginate } from "../../../ui/data-table/data-table-utils";
import "./profile-statistics.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import dayjs, { Dayjs } from "dayjs";

interface IProfileStatisticsProps {
  isLoading?: boolean;
  filterPeriod: ProfileFilterCriteriaType | null;
  rangeDate: [Dayjs | null, Dayjs | null];
  data: IProfilesGeneralStatistics;
}

const TABLE_COLUMNS = [
  { displayName: "Upwork profile" },
  { displayName: "Upwex user" },
  { displayName: "Used Connections" },
  { displayName: "Invites" },
  { displayName: "DM" },
  { displayName: "Proposals" },
  { displayName: "Reply" },
  { displayName: "Reply Rate" },
];

const baseClassName = "profile-statistics";

export const ProfileStatistics = ({
  isLoading = false,
  filterPeriod,
  rangeDate,
  data,
}: IProfileStatisticsProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const navigate = useNavigate();

  const handleBidClick = (upworkUserId: number, upwexUserId: string): void => {
    const timePeriod = !!filterPeriod ? `&time_period=${filterPeriod}` : "";
    const datePeriod =
      rangeDate[0] && rangeDate[1]
        ? `&date_from=${rangeDate[0].format(
            "YYYY-MM-DD"
          )}&date_to=${rangeDate[1].format("YYYY-MM-DD")}`
        : "";
    navigate(
      `${APP_ROUTES.proposals}?upwork_user_id=${upworkUserId}&upwex_user_id=${upwexUserId}${timePeriod}${datePeriod}`
    );
  };

  const handlePagination = (perPage: number, requestedPage: number): void => {
    const paginateData = paginate(data.profiles, perPage, requestedPage);
    const actualPage =
      paginateData.length > 0
        ? requestedPage
        : Math.ceil(data.profiles.length / perPage);
    setPerPage(perPage);
    setCurrentPage(actualPage);
  };

  const getTableData = () => {
    return (
      data?.profiles?.map((profile) => ({
        avatar: (
          <div className="avatar-wrap">
            <Avatar
              imageSrc={
                profile.upwork_user.portrait.portrait ||
                profile.upwork_user.portrait.portrait500
              }
              size="small"
            />
            <span className="user-name">{profile.upwork_user.username}</span>
          </div>
        ),
        email: profile.email,
        used_connects: profile.used_connects,
        invites: profile.invites,
        direct_messages: profile.direct_messages,
        bid: (
          <div
            className="clickable-value"
            onClick={() =>
              handleBidClick(profile.upwork_user.id, profile.upwex_user_id)
            }
          >
            {profile.bids}
            <FaExternalLinkAlt />
          </div>
        ),
        replies: profile.replies,
        reply_rate: `${profile.reply_rate.toFixed(2)}%`,
      })) || []
    );
  };

  const getTableTotals = () => {
    return [
      { label: "Total", value: "" },
      { label: "Upwex User", value: "", skipOnMobile: true },
      { label: "Used Connections", value: data?.total?.used_connects || 0 },
      { label: "Invites", value: data?.total?.invites },
      { label: "Direct Messages", value: data?.total?.direct_messages },
      { label: "Proposals", value: data?.total?.bids },
      { label: "Reply", value: data?.total?.replies },
      { label: "Reply Rate", value: `${data?.total?.reply_rate?.toFixed(2)}%` },
    ];
  };

  return (
    <div className={baseClassName}>
      <DataTable
        isLoading={isLoading}
        columns={TABLE_COLUMNS}
        currentPage={currentPage}
        perPage={perPage}
        data={getTableData()}
        totals={getTableTotals()}
        onPagination={handlePagination}
      />
    </div>
  );
};

ProfileStatistics.propTypes = {
  isLoading: PropTypes.bool,
  filterPeriod: PropTypes.oneOf([
    "current_week",
    "last_week",
    "current_month",
    "last_month",
    "3_months",
    "6_months",
    "current_year",
    "all_time",
  ]).isRequired,
  data: PropTypes.object.isRequired,
};
