import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { settingsApi, toolsApi } from "../../api";
import {
  ERROR_MESSAGES,
  IGetPipedriveConnectionResponse,
  IGetSettingsResponse,
  IResponseError,
} from "../../data-access";
import { storeSettingsActions } from "../../store/slices/settings";
import { storeToolsActions } from "../../store/slices/tools";
import { Panel, Spinner } from "../../ui";
import { handleFailedRequest, notify } from "../../util";
import { CrmSettingsForm } from "./crm-settings-form";
import "./settings.scss";
import { AISettingsForm } from "./ai-settings-form";

export const Settings = (): JSX.Element => {
  const [isFetchingPipedriveConnection, setIsFetchingPipedriveConnection] =
    useState<boolean>(true);
  const [isFetchingSettings, setIsFetchingSettings] = useState<boolean>(true);

  const dispatch = useDispatch();

  const fetchPipedriveConnectionStatus = useCallback((): void => {
    toolsApi
      .getPipedriveStatus()
      .then((response: IGetPipedriveConnectionResponse) => {
        dispatch(
          storeToolsActions.setPipedriveConnection({
            is_connected: response.data.is_connected,
          })
        );
      })
      .catch(() => {
        notify("error", ERROR_MESSAGES.FETCH_PIPEDRIVE_FAIL);
      })
      .finally(() => setIsFetchingPipedriveConnection(false));
  }, [dispatch]);

  const fetchSettings = useCallback((): void => {
    settingsApi
      .getSettings()
      .then((response: IGetSettingsResponse) => {
        dispatch(storeSettingsActions.setCrmSettings({ ...response.data.crm }));
        dispatch(
          storeSettingsActions.setAISettings({ ...response.data.job_assistant })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsFetchingSettings(false));
  }, [dispatch]);

  useEffect(() => {
    fetchPipedriveConnectionStatus();
    fetchSettings();
  }, [fetchPipedriveConnectionStatus, fetchSettings]);

  const isLoading = isFetchingPipedriveConnection || isFetchingSettings;

  return (
    <>
      <Panel className="settings" title="AI Job Assistant">
        {isLoading ? (
          <Spinner />
        ) : (
          <AISettingsForm isFetching={isFetchingPipedriveConnection} />
        )}
      </Panel>
      <Panel className="settings" title="CRM Settings">
        {isLoading ? (
          <Spinner />
        ) : (
          <CrmSettingsForm isFetching={isFetchingPipedriveConnection} />
        )}
      </Panel>
    </>
  );
};

export default Settings;
