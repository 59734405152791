import { FaChartBar } from 'react-icons/fa';
import './empty-chart.scss';

const baseClassName = 'empty-chart';

export const EmptyChart = (): JSX.Element => {
  return (
    <div className={baseClassName}>
      <div className="icon">
        <FaChartBar />
      </div>
      <span>Looks like you have no data for selected period.</span>
    </div>
  );
}