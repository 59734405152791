import { baseApiPath, get, patch } from "./base-api";
import {
  IGetSettingsResponse,
  ICrmSettings,
  IAISettings,
} from "../data-access";

const getSettings = (): Promise<IGetSettingsResponse> => {
  return get<IGetSettingsResponse>(`${baseApiPath}/api/settings`);
};

const updateSettings = (
  settings: ICrmSettings
): Promise<IGetSettingsResponse> => {
  return patch<ICrmSettings, IGetSettingsResponse>(
    `${baseApiPath}/api/settings/crm`,
    settings
  );
};

const updateAIJobSettings = (
  settings: IAISettings
): Promise<IGetSettingsResponse> => {
  return patch<IAISettings, IGetSettingsResponse>(
    `${baseApiPath}/api/settings/job-assistant`,
    settings
  );
};

export const settingsApi = {
  getSettings,
  updateSettings,
  updateAIJobSettings,
};
