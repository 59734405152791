import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Tag, Tooltip } from "antd";

import { ColumnAlign } from "../../../data-access";
import { DataTable, FormButton } from "../../../ui";
import { paginate } from "../../../ui/data-table/data-table-utils";
import "./jobs.scss";
import { FaClock, FaExternalLinkAlt, FaEye } from "react-icons/fa";
import { JobInfo } from "../job-info";
import { researcherApi } from "../../../api/job-researcher";

interface IJobsProps {
  isLoading?: boolean;
  bidFilter: string;
  emitStatistics: (data: any) => void;
}

const TABLE_COLUMNS = [
  { displayName: "Title", textAlign: ColumnAlign.LEFT },
  { displayName: "Budget" },
  { displayName: "Published" },
  { displayName: "Client Rating" },
  { displayName: "Matched Jobs" },
  { displayName: "BID" },
  { displayName: "Reply" },
  { displayName: "" },
];

const baseClassName = "jobs";

const tooltipBidYesText =
  "A bid has been found for this job, placed by you using the Upwex extension.";
const tooltipBidNoText =
  "Upwex couldn't find a bid for this job. You haven’t placed a bid yet using the Upwex extension.";
const tooltipReplyYesText =
  "A reply has been found for this job, sent by you using the Upwex extension.";
const tooltipReplyNoText =
  "Upwex couldn't find a reply for this job. You haven’t replied yet using the Upwex extension.";

export const Jobs = ({
  isLoading,
  bidFilter,
  emitStatistics,
}: IJobsProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalJobs, setTotalJobs] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [jobInfo, setJobInfo] = useState({});
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);

  const [data, setData] = useState([]);

  const handleOpenJobInfo = (job: any) => {
    setJobInfo(job);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let getIntervalId: ReturnType<typeof setInterval> | string = "";

    getIntervalId = setInterval(() => {
      researcherApi
        .getJobResearcherTaskJobs("1", bidFilter, currentPage, perPage)
        .then((response) => {
          const {
            data: {
              jobs,
              pagination: { page, total_records },
              stats,
            },
          } = response;

          setTotalJobs(total_records);
          setCurrentPage(page);
          setData(jobs);
          emitStatistics(stats);
        })
        .finally(() => setIsProcessingRequest(false));
    }, 60000);

    return () => {
      getIntervalId && clearInterval(getIntervalId);
    };
  }, [bidFilter, currentPage, perPage]);

  useEffect(() => {
    setIsProcessingRequest(true);
    researcherApi
      .getJobResearcherTaskJobs("1", bidFilter, currentPage, perPage)
      .then((response) => {
        const {
          data: {
            jobs,
            pagination: { page, total_records },
            stats,
          },
        } = response;

        if (!jobs.length && page > 1) {
          handlePagination(perPage, 1);
          return;
        }

        setTotalJobs(total_records);
        setCurrentPage(page);
        setData(jobs);
        emitStatistics(stats);
      })
      .finally(() => setIsProcessingRequest(false));
  }, [bidFilter, currentPage, perPage]);

  const handlePagination = (perPage: number, requestedPage: number): void => {
    setPerPage(perPage);
    setCurrentPage(requestedPage);
  };

  const getTableData = () => {
    return (
      data?.map((job: any) => ({
        title: (
          <a
            className="clickable-value job-link"
            href={`https://www.upwork.com/jobs/${job.job_id}`}
            target="_blank"
          >
            <span>{job.title}&nbsp;</span> <FaExternalLinkAlt />
          </a>
        ),
        budget: (
          <div className="clickable-value">
            {job.payment_type === "Hourly-price" && <FaClock />}
            {job.budget}
          </div>
        ),
        job_posted: moment(job.job_posted).fromNow(),
        rating: `${job.rating || 0}(${job.total_reviews || 0})`,
        potential_bid:
          job.potential_bid === "Yes" ? (
            <Tag color={"green"}>Yes</Tag>
          ) : (
            <Tag color={"red"}>No</Tag>
          ),
        has_bid: job?.has_bid ? (
          <Tooltip title={tooltipBidYesText} color="#fff">
            <div className="job-tool success-tool"></div>
          </Tooltip>
        ) : (
          <Tooltip title={tooltipBidNoText} color="#fff">
            <div className="job-tool error-tool"></div>
          </Tooltip>
        ),
        has_reply: job?.has_reply ? (
          <Tooltip title={tooltipReplyYesText} color="#fff">
            <div className="job-tool success-tool"></div>
          </Tooltip>
        ) : (
          <Tooltip title={tooltipReplyNoText} color="#fff">
            <div className="job-tool error-tool"></div>
          </Tooltip>
        ),
        actions: (
          <div className={`${baseClassName}__actions`}>
            <FormButton theme="blue" onClick={() => handleOpenJobInfo(job)}>
              <FaEye />
            </FormButton>
          </div>
        ),
      })) || []
    );
  };

  return (
    <div className={baseClassName}>
      <DataTable
        isLoading={isProcessingRequest}
        columns={TABLE_COLUMNS}
        perPage={perPage}
        currentPage={currentPage}
        data={getTableData()}
        customPagination={true}
        isSpecificPagination={true}
        onPagination={handlePagination}
        totalRecords={totalJobs}
      />
      <JobInfo open={open} onClose={onClose} data={jobInfo} />
    </div>
  );
};

Jobs.propTypes = {
  isLoading: PropTypes.bool,
  taskId: PropTypes.string,
};
