import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { ForwardedRef, forwardRef } from 'react';
import './switch.scss';

interface IFormSwitchProps {
  onChange: (value: any) => void;
  className?: string;
  isChecked?: boolean;
  disabled?: boolean;
  checkedChildren?: string | boolean;
  unCheckedChildren?: string | boolean;
}

export const FormSwitch = forwardRef(({
  className = '', isChecked, disabled = false, onChange, checkedChildren, unCheckedChildren
}: IFormSwitchProps, ref: ForwardedRef<any>): JSX.Element => {
  return (
    <Switch
      ref={ref}
      defaultChecked={isChecked}
      disabled={disabled}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      className={`${className} switch`}
      onChange={onChange}
    />
  );
});

FormSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  checkedChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  unCheckedChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default FormSwitch;