import axios from "axios";
import { usersApi } from ".";
import { store } from "../store";
import { storeAuthActions } from "../store/slices/auth";
import { logoutUser } from "../util";

let isRefreshing = false;
let failedQueue = [] as any;

export const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  (config: any) => {
    const auth_token = store.getState().auth.access_token;

    config.headers = {
      ...(auth_token && { Authorization: `Bearer ${auth_token}` }),
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch(() => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        usersApi
          .refreshToken({ refresh_token: store.getState().auth.refresh_token })
          .then((response) => {
            axiosApiInstance.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access_token;
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.access_token;
            store.dispatch(
              storeAuthActions.setUserData({
                access_token: response.data.access_token,
              })
            );
            processQueue(null, response.data.access_token);
            resolve(axiosApiInstance(originalRequest));
          })
          .catch(() => {
            processQueue(err, null);
            reject(err);
            logoutUser();
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);
