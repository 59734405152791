import { Dropdown, Tag } from "antd";
import { FaCheckCircle, FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { usersApi } from "../../api";
import { appConfig } from "../../app-config";
import { useExtensionDetails, useUser } from "../../hooks";
import { Avatar, Spinner } from "..";
import "./topbar-panel.scss";
import { cookie, getTrialPlanDayTillExpired } from "../../util";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { storeGenericActions } from "../../store/slices/generic";

export const TopBarPanel = (): JSX.Element => {
  const { currentUser, resetUser } = useUser();
  const {
    isInstalled: isExtensionInstalled,
    versionInstalled: extensionVersionInstalled,
  } = useExtensionDetails();
  const [isDetectingExtension, setIsDetectingExtension] =
    useState<boolean>(true);
  const [notificationsVisibility, setNotificationsVisibility] = useState<{
    [key: string]: boolean | undefined;
  }>({
    trialPeriod: undefined,
    subscriptionNotAssing: undefined,
    subscriptionExpired: undefined,
    noExtension: undefined,
    upgradeExtensionVersion: undefined,
  });

  const dispatch = useDispatch();

  const currentPath = window.location.pathname;
  const isOldExtensionVersion =
    extensionVersionInstalled !== appConfig.actualExtensionVersion;

  useEffect(() => {
    const detectingExtensionTimerId = setTimeout(() => {
      if (typeof isExtensionInstalled === "boolean") {
        setIsDetectingExtension(false);
      }
    }, 1500);

    return () => {
      clearTimeout(detectingExtensionTimerId);
    };
  }, [isExtensionInstalled]);

  useEffect(() => {
    if (isExtensionInstalled !== undefined) {
      dispatch(
        storeGenericActions.update({
          extensionInstalled: isExtensionInstalled,
          oldExtensionVersion: isOldExtensionVersion,
        })
      );
    }

    const showExtensionInstallNotification =
      !cookie.get("npn") && !isDetectingExtension && !isExtensionInstalled;
    const showUpgradeExtensionNotification =
      !isDetectingExtension &&
      isExtensionInstalled &&
      extensionVersionInstalled !== appConfig.actualExtensionVersion;
    const showTrialPeriodNotification =
      !!currentUser && currentUser?.trial_expires_at !== null;
    const showSubscriptionNotAssingNotification =
      !!currentUser && currentUser?.license === null;
    const showSubscriptionExpiredNotification =
      !!currentUser && currentUser?.license !== null && !currentUser?.paid_user;

    setNotificationsVisibility({
      noExtension: showExtensionInstallNotification,
      trialPeriod: showTrialPeriodNotification,
      subscriptionNotAssing: showSubscriptionNotAssingNotification,
      subscriptionExpired: showSubscriptionExpiredNotification,
      upgradeExtensionVersion: showUpgradeExtensionNotification,
    });
  }, []);

  const subscriptionTrialPeriodLeft = getTrialPlanDayTillExpired(
    String(currentUser?.trial_expires_at)
  );

  const handleLogout = (): void => {
    usersApi
      .fullyLogoutUser()
      .then(() => {
        cookie.remove("__lc_cid");
        cookie.remove("__lc_cst");
        localStorage.clear();
      })
      .catch(() => {})
      .finally(resetUser);
  };

  const handleExtensionStatusClick = (): void => {
    if (!isExtensionInstalled) {
      window.open(appConfig.extensionDownloadLink, "_blank", "noreferrer");
    } else if (isOldExtensionVersion) {
      window.open(appConfig.extensionUpdateLink, "_blank", "noreferrer");
    }

    return;
  };

  const items = [
    {
      key: "logout",
      label: (
        <span className="logout-button" onClick={handleLogout}>
          Logout
        </span>
      ),
    },
  ];

  return (
    <div className="container top-bar">
      <Dropdown menu={{ items }}>
        <div className="avatar-container">
          <div className="user-details">
            <span className="details">
              {currentUser?.full_name || "Upwex User"}
            </span>
            <span className="details">{currentUser?.email}</span>
          </div>
          <Avatar
            imageSrc={currentUser?.picture || "/user_profile_avatar.svg"}
            imageAlt={currentUser?.full_name}
          />
          <span className="online-indicator" />
        </div>
      </Dropdown>

      <div className="toobar-notification">
        {notificationsVisibility.trialPeriod && (
          <Tag
            className="extension-status"
            color={
              subscriptionTrialPeriodLeft > 1
                ? subscriptionTrialPeriodLeft === 1
                  ? "orange"
                  : "green"
                : "red"
            }
            onClick={handleExtensionStatusClick}
          >
            <div className="tag-link">
              Trial ends in{" "}
              <strong>&nbsp;{subscriptionTrialPeriodLeft}&nbsp;</strong> days
              <FaCheckCircle
                className={
                  subscriptionTrialPeriodLeft > 1
                    ? subscriptionTrialPeriodLeft === 1
                      ? "orange"
                      : "green"
                    : "red"
                }
              />
            </div>
          </Tag>
        )}
        {!notificationsVisibility.trialPeriod &&
          notificationsVisibility.subscriptionNotAssing && (
            <Tag
              className="extension-status"
              color="red"
              onClick={handleExtensionStatusClick}
            >
              <div className="tag-link">
                License not assign
                <FaTimesCircle className="failed" />
              </div>
            </Tag>
          )}
        {!notificationsVisibility.trialPeriod &&
          notificationsVisibility.subscriptionExpired && (
            <Tag
              className="extension-status"
              color="red"
              onClick={handleExtensionStatusClick}
            >
              <div className="tag-link">
                Your subscription has expired
                <FaTimesCircle className="failed" />
              </div>
            </Tag>
          )}
        {!notificationsVisibility.trialPeriod &&
          !notificationsVisibility.subscriptionNotAssing &&
          !notificationsVisibility.subscriptionExpired && (
            <Tag
              className="extension-status"
              color="green"
              onClick={handleExtensionStatusClick}
            >
              <div className="tag-link">
                Subscription active <FaCheckCircle className="success" />
              </div>
            </Tag>
          )}

        {isDetectingExtension && <Spinner />}
        {!isDetectingExtension && (
          <Tag
            className="extension-status"
            color={
              !notificationsVisibility.noExtension
                ? notificationsVisibility.upgradeExtensionVersion
                  ? "orange"
                  : "green"
                : "red"
            }
            onClick={handleExtensionStatusClick}
          >
            {!notificationsVisibility.noExtension ? (
              notificationsVisibility.upgradeExtensionVersion ? (
                <div className="tag-link">
                  Upwex Extension not updated
                  <FaCloudUploadAlt className="updated" />
                </div>
              ) : (
                <>
                  Upwex Extension installed
                  <FaCheckCircle className="success" />
                </>
              )
            ) : (
              <div className="tag-link">
                Upwex Extension not installed
                <FaTimesCircle className="failed" />
              </div>
            )}
          </Tag>
        )}
      </div>
    </div>
  );
};
