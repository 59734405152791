import {baseApiPath, deleteEntity, get, post} from './base-api';
import { IAuthorizedPipedrive, IAuthResponse } from '../data-access';

export const getPipedriveStatus = (): Promise<{ data: { is_connected: boolean; } }> => {
  return get<{ data: { is_connected: boolean; } }>(`${baseApiPath}/api/tools-and-apps/pipedrive/exist`);
}

export const authorizePipedrive = ({ access_code, state }: IAuthorizedPipedrive): Promise<IAuthResponse> => {
  return post<IAuthorizedPipedrive, IAuthResponse>(`${baseApiPath}/api/tools-and-apps/pipedrive/auth`, { access_code, state });
}

export const deletePipedrive = (): Promise<void> => {
  return deleteEntity(`${baseApiPath}/api/tools-and-apps/pipedrive/delete`);
}

export const getPipedriveAuthUrl = (): Promise<{ data: { url: string } }> => {
  return get(`${baseApiPath}/api/tools-and-apps/pipedrive/authUrl`);
}

export const toolsApi = {
  getPipedriveStatus,
  authorizePipedrive,
  deletePipedrive,
  getPipedriveAuthUrl
};