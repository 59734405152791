import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DiscountForm } from "./discount-form";
import { billingApi } from "../../api";
import { IPromoCodeAddResponse, IResponseError } from "../../data-access";
import { storeBillingActions } from "../../store/slices/billing";
import { ModalDialog } from "../../ui";
import { handleFailedRequest, notify } from "../../util";

interface IDiscountModalProps {
  onClose: () => void;
}

export const DiscountModal = ({
  onClose,
}: IDiscountModalProps): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();

  const handleDiscountApply = (values: { discount: string }): void => {
    setIsProcessing(true);
    billingApi
      .applyPromoCode(values.discount)
      .then((response: IPromoCodeAddResponse) => {
        dispatch(storeBillingActions.update({ discount: response.data }));
        notify("success", "Discount has been successfully applied.");
        onClose();
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessing(false));
  };

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  };

  return (
    <ModalDialog
      title="Add promo code"
      enableActionButtons={true}
      confirmText="Apply"
      isOpen={true}
      confirmLoading={isProcessing}
      onConfirm={() => {
        formRef?.current?.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }}
      onCancel={handleClose}
    >
      <DiscountForm
        ref={formRef}
        isLoading={isProcessing}
        onSubmit={handleDiscountApply}
      />
    </ModalDialog>
  );
};

DiscountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
