import React from "react";
import "./welcome-modal-content.scss";

export const WelcomeModalContent = (): JSX.Element => {
  return (
    <div className="welcome__container">
      <div className="welcome__contact">
        <div className="welcome__image">
          <img src="/favicon.ico" />
        </div>
        <div className="welcome__contact-info">
          <p>Upwex</p>
          <span>Team</span>
        </div>
      </div>

      <div className="welcome__content">
        <p>Hi 👋🏻</p>
        <p>
          Welcome to Upwex! Why don't you chect out our{" "}
          <a href="https://help.upwex.io/en/" target="_blank">
            tutorials
          </a>{" "}
          to help get you started?
        </p>

        <iframe
          className="welcome__video"
          src="https://www.youtube.com/embed/80aZR069-QE?si=QFO2Zn1Qi7kBR0Sy"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>

        <p>
          Regards, <br /> Upwex Team
        </p>
      </div>
    </div>
  );
};
