import React from "react";
import { BillingLicences } from "./billing-licences";
import { CurrentPlan } from "./current-plan";
import { PaymentHistory } from "./payment-history";
import "./billing.scss";

export const Billing = (): JSX.Element => {
  return (
    <div className="billing">
      <CurrentPlan />
      <BillingLicences />
      <PaymentHistory />
    </div>
  );
};

export default Billing;
