export interface IRelease {
  version: string;
  published_at: string;
  fixesNumber?: number;
  featuresNumber?: number;
  intro?: string;
  year: string;
  points: { title?: string; description: string; images?: string[] }[];
}

export const releasesData: IRelease[] = [
  {
    version: "3.1.0",
    published_at: "November 05, 2024",
    featuresNumber: 1,
    fixesNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Added AI Job Assistant for opened jobs",
      },
      {
        title: "Extension:",
        description: "Fixed BID statistics",
      },
    ],
  },
  {
    version: "3.0.7",
    published_at: "October 09, 2024",
    featuresNumber: 2,
    fixesNumber: 1,
    year: "2024",
    points: [
      {
        title: "Dashboard:",
        description: "Added AI Job Researcher",
      },
      {
        title: "Dashboard:",
        description: "Added Upwex API",
      },
      {
        title: "Extension:",
        description: "Fixed statistics issues",
      },
    ],
  },
  {
    version: "3.0.3",
    published_at: "August 07, 2024",
    featuresNumber: 2,
    fixesNumber: 3,
    year: "2024",
    points: [
      {
        title: "Dashboard:",
        description: "Added Google Authorization",
      },
      {
        title: "CRM:",
        description: "Fixed Sync/Update with CRM",
      },
      {
        title: "Authorization:",
        description: "Added multi session authorization",
      },
      {
        title: "Extension:",
        description: "Fixed authorization error message",
      },
      {
        title: "Extension:",
        description: "Fixed sync BID buttons",
      },
    ],
  },
  {
    version: "3.0.2",
    published_at: "July 11, 2024",
    fixesNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed proposal synced UI",
      },
    ],
  },
  {
    version: "3.0.1",
    published_at: "June 28, 2024",
    fixesNumber: 2,
    featuresNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed multiple Generate AI Proposal block",
      },
      {
        title: "Extension:",
        description: "Added Sync Profile notice/animation",
      },
      {
        title: "Extension:",
        description: "Fixed Sync Proposal Notice",
      },
    ],
  },
  {
    version: "3.0.0",
    published_at: "June 27, 2024",
    featuresNumber: 7,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "New version of design",
      },
      {
        title: "Extension:",
        description: "Added AI Job Assistant",
      },
      {
        title: "Extension:",
        description: "Removed Job Score",
      },
      {
        title: "Extension:",
        description: "Added Sync Upwork Profiles",
      },
      {
        title: "Extension:",
        description: "Fixed Statistics",
      },
      {
        title: "Extension:",
        description:
          "Added more user interactivity with the extension/new tabs",
      },
      {
        title: "Dashboard:",
        description: "Added new settings for AI Job Assistant",
      },
    ],
  },
  {
    version: "2.2.6",
    published_at: "May 16, 2024",
    featuresNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed Upwork sync bid label/button visible.",
      },
    ],
  },
  {
    version: "2.2.5",
    published_at: "May 10, 2024",
    featuresNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed Upwork user information.",
      },
    ],
  },
  {
    version: "2.2.4",
    published_at: "March 25, 2024",
    featuresNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed auth API issue.",
      },
    ],
  },
  {
    version: "2.2.3",
    published_at: "February 28, 2024",
    featuresNumber: 4,
    year: "2024",
    points: [
      {
        title: "Dashboard:",
        description: "Replaced Analytics to Dashboard.",
      },
      {
        title: "Dashboard:",
        description: "Updated Billing flow and design.",
      },
      {
        title: "Dashboard:",
        description: "Updated Team page.",
      },
      {
        title: "Dashboard:",
        description: "Added mobile design.",
      },
    ],
  },
  {
    version: "2.2.2",
    published_at: "February 27, 2024",
    fixesNumber: 2,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed Sync/Synced bids bug.",
      },
      {
        title: "Extension:",
        description: "Fixed used connects count bug.",
      },
    ],
  },
  {
    version: "2.2.1",
    published_at: "January 23, 2024",
    fixesNumber: 3,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Fixed Upwex AI user country.",
      },
      {
        title: "Extension:",
        description: "Fixed Pipedrive.",
      },
      {
        title: "Extension:",
        description: "Fixed Payment.",
      },
    ],
  },
  {
    version: "2.2.0",
    published_at: "January 15, 2024",
    fixesNumber: 2,
    featuresNumber: 1,
    year: "2024",
    points: [
      {
        title: "Extension:",
        description: "Added Upwex AI generator.",
        images: [`${window.location.origin}/releases/2.2.0/1.jpg`],
      },
      {
        title: "Extension:",
        description: "Fixed messages button UI.",
      },
      {
        title: "Extension:",
        description: "Fixed error alerts.",
      },
    ],
  },
  {
    version: "2.1.7",
    published_at: "December 16, 2023",
    fixesNumber: 2,
    year: "2023",
    points: [
      {
        title: "Dashboard:",
        description: "Updated Lite Plan.",
      },
      {
        title: "Dashboard:",
        description: "Fixed UI for AI buttons.",
      },
    ],
  },
  {
    version: "2.1.6",
    published_at: "December 6, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        title: "CRM:",
        description: "Fixed CRM type messages.",
      },
    ],
  },
  {
    version: "2.1.5",
    published_at: "December 5, 2023",
    fixesNumber: 2,
    featuresNumber: 2,
    year: "2023",
    points: [
      {
        title: "Extension:",
        description: "Added the ability to manually add proposals.",
        images: [`${window.location.origin}/releases/2.1.5/1.gif`],
      },
      {
        title: "Extension:",
        description: "Fixed UI.",
      },
      {
        title: "CRM:",
        description: "Fixed creation of Deals.",
      },
      {
        title: "Dashboard:",
        description:
          "Instructions for changing the tariff plan have been added.",
      },
    ],
  },
  {
    version: "2.1.4",
    published_at: "November 21, 2023",
    fixesNumber: 2,
    year: "2023",
    points: [
      {
        title: "Extension:",
        description: "Fixed job open count statistics.",
      },
      {
        title: "Extension:",
        description: "Fixed job scoring content visibility.",
      },
    ],
  },
  {
    version: "2.1.3",
    published_at: "November 17, 2023",
    fixesNumber: 3,
    year: "2023",
    points: [
      {
        title: "Extension:",
        description: "Fixed job scoring for home page.",
      },
      {
        title: "Extension:",
        description: "Fixed invites statistics.",
      },
      {
        title: "Extension:",
        description:
          "Fixed freelancer names for agency to generate cover letters.",
      },
    ],
  },
  {
    version: "2.1.2",
    published_at: "November 3, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        title: "Extension:",
        description: "Fixed generate AI proposals/Q&A.",
      },
    ],
  },
  {
    version: "2.1.1",
    published_at: "October 31, 2023",
    fixesNumber: 1,
    featuresNumber: 1,
    year: "2023",
    points: [
      {
        title: "Extension:",
        description: "Added prompt management.",
        images: [`${window.location.origin}/releases/2.1.1/1.gif`],
      },
      {
        title: "Extension:",
        description: "Fixed auto updating CRM.",
      },
    ],
  },
  {
    version: "2.1.0",
    published_at: "October 26, 2023",
    featuresNumber: 4,
    year: "2023",
    points: [
      {
        title: "Dashboard:",
        description: "Expanded proposals analytics (2-step line analytics).",
        images: [
          `${window.location.origin}/releases/2.1.0/1.png`,
          `${window.location.origin}/releases/2.1.0/2.png`,
        ],
      },
      {
        title: "Extension:",
        description: "Added functionality for add/edit prompts.",
      },
      {
        title: "Extension:",
        description: "Added automatic updating of chat messages for CRM.",
      },
      {
        title: "Extension:",
        description: "Added job score UI loader.",
      },
    ],
  },
  {
    version: "2.0.5",
    published_at: "October 24, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        title: "Extension:",
        description: "Fixed job scoring errors.",
      },
    ],
  },
  {
    version: "2.0.4",
    published_at: "October 21, 2023",
    fixesNumber: 3,
    year: "2023",
    points: [
      {
        title: "Dashboard:",
        description: "Added a notice for update extension version.",
      },
      {
        title: "Extension:",
        description: "Fixed Job scoring.",
      },
      {
        title: "Extension:",
        description: "Fixed cover letter generation and Q&A.",
      },
    ],
  },
  {
    version: "2.0.3",
    published_at: "October 4, 2023",
    fixesNumber: 4,
    year: "2023",
    points: [
      {
        title: "Dashboard:",
        description: "Added a notice for update extension version.",
      },
      {
        title: "CRM:",
        description: "Added Lead in popup and message writer name.",
      },
      {
        title: "Extension:",
        description: "Changed last month statistics to current.",
      },
      {
        title: "General:",
        description: "Fixed data bugs.",
      },
    ],
  },
  {
    version: "2.0.2",
    published_at: "September 26, 2023",
    fixesNumber: 3,
    year: "2023",
    points: [
      {
        title: "CRM:",
        description: "Fixed synchronisation.",
      },
      {
        title: "Analytics:",
        description: "Fixed Upwork user statistics.",
      },
      {
        title: "General:",
        description: "Fixed duplicate notices.",
      },
    ],
  },
  {
    version: "2.0.1",
    published_at: "September 3, 2023",
    fixesNumber: 2,
    year: "2023",
    points: [
      {
        title: "Analytics:",
        description: "Fixed bid count statistics.",
      },
      {
        title: "CRM:",
        description: "Fixed update stage name.",
      },
    ],
  },
  {
    version: "2.0.0",
    published_at: "August 29, 2023",
    fixesNumber: 1,
    featuresNumber: 3,
    year: "2023",
    points: [
      {
        title: "Optimized Algorithms:",
        description:
          "We've updated all our algorithms. This boosts the speed of our application and enhances security. We are proud to say that we have the safest application for working with Upwork.",
      },
      {
        title: "Advanced Analytics:",
        description:
          "Our revamped analytics algorithms ensure that no metric is overlooked or missed.",
      },
      {
        title: "Extended Pipedrive Functionality with a Refreshed Interface:",
        description:
          "Created a new popup with CRM Deals, Account Information/Updated CRM Sync flow, added a new popup with CRM Settings. You can now view all your deals directly within Upwork through the Upwex widget. This seamless integration will undoubtedly enhance your productivity.",
        images: [`${window.location.origin}/releases/2.0.0/1.png`],
      },
      {
        title: "Trial period:",
        description: "Increased trial period from 3 to 10 days.",
      },
      {
        title: "Pipeline Selection for Sync:",
        description:
          "Customize your experience by choosing your desired pipeline during sync.",
      },
      {
        title: "Lead Creation Setting:",
        description:
          "A new setting has been added, giving you the option to enable lead creation.",
        images: [`${window.location.origin}/releases/2.0.0/2.png`],
      },
      {
        title: "Two New Analytics Metrics:",
        description:
          "We've introduced AVG Connects, which displays the average number of connects spent on each bid, and Cost Reply, indicating the cost of a single reply.",
        images: [`${window.location.origin}/releases/2.0.0/3.png`],
      },
    ],
  },
  {
    version: "1.3.5",
    published_at: "July 26, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        description:
          "Unveiling a polished UI experience for job notifications.",
        images: [`${window.location.origin}/releases/1.3.5/1.png`],
      },
    ],
  },
  {
    version: "1.3.4",
    published_at: "July 19, 2023",
    featuresNumber: 5,
    year: "2023",
    intro: "Here’s a quick rundown of the new features you'll find:",
    points: [
      {
        title: "Profile Analytics:",
        description:
          "We've added a new section in the analytics area that displays statistics across all Upwork profiles you've used. Now, you can view data per Upwork profile, as well as per Upwex user.",
        images: [`${window.location.origin}/releases/1.3.4/1.png`],
      },
      {
        title: "Connects History:",
        description:
          "This new feature shows how many connects have been used for each profile.",
        images: [`${window.location.origin}/releases/1.3.4/2.png`],
      },
      {
        title: "Improved Filtering:",
        description:
          "Based on your feedback, we've revamped our filters. The available time frames now include: current week, last week, current month, last month, 3 months, 6 months, current year, and all time.",
        images: [`${window.location.origin}/releases/1.3.4/3.png`],
      },
      {
        title: "Job Post View History:",
        description:
          "You can now see if anyone from your team has viewed a Job post, either in your feed. The details provided include who viewed it and the time.",
        images: [`${window.location.origin}/releases/1.3.4/4.png`],
      },
      {
        title: "Bid History:",
        description:
          "When viewing a Job post, you can now see who from your team has placed a bid, and when they did so.",
        images: [`${window.location.origin}/releases/1.3.4/5.png`],
      },
    ],
  },
  {
    version: "1.3.3",
    published_at: "July 14, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        description: 'Fixed the "Update CRM" button for seamless interactions.',
      },
    ],
  },
  {
    version: "1.3.2",
    published_at: "July 11, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        description:
          "Refined our AI generation controls for an optimized user experience.",
      },
    ],
  },
  {
    version: "1.3.1",
    published_at: "June 30, 2023",
    featuresNumber: 4,
    year: "2023",
    points: [
      {
        description:
          "Integrated the power of GPT-4 to improve the quality of features.",
      },
      {
        description:
          "Introduced the Pipedrive private app for enhanced connectivity.",
      },
      {
        description:
          "Unveiled Job Score metrics on Job Details and Proposal pages, ensuring you're always in the know.",
      },
      {
        description:
          "Personal touch added: Now display your agency's username when crafting AI Proposals.",
      },
    ],
  },
  {
    version: "1.3.0",
    published_at: "June 22, 2023",
    fixesNumber: 4,
    featuresNumber: 1,
    year: "2023",
    points: [
      {
        description:
          "Breathed new life into the extension with a sleek redesign.",
      },
      {
        description:
          "Unveiled a fresh, sleek design for our extension. Style meets function!",
      },
      {
        description:
          "Introducing the License Manager – your key to hassle-free license management.",
      },
      {
        description:
          "Fine-tuned the extension popup for a more intuitive experience.",
      },
      {
        description:
          "Simplified your experience by saying goodbye to redundant Extension settings. Cleaner, smarter, better.",
      },
    ],
  },
  {
    version: "1.2.2",
    published_at: "June 16, 2023",
    featuresNumber: 1,
    year: "2023",
    points: [
      {
        description:
          "Introducing the ability to save your custom AI Requests – curate and revisit your creative insights effortlessly!",
      },
    ],
  },
  {
    version: "1.2.1",
    published_at: "June 9, 2023",
    fixesNumber: 2,
    year: "2023",
    points: [
      {
        description:
          "Refreshed our aesthetic for the connected API key notification – clarity with a touch of elegance.",
      },
      {
        description:
          "Fine-tuned the extension popup link for a seamless navigation experience.",
      },
    ],
  },
  {
    version: "1.2.0",
    published_at: "June 6, 2023",
    featuresNumber: 3,
    year: "2023",
    points: [
      {
        description:
          "We've implemented the feature to Сustom AI requests for Proposals.",
      },
      {
        description:
          "We've introduced Direct Messages statistics for better tracking of interactions.",
      },
      {
        description:
          "We've added Invite statistics to monitor the growth of your invites.",
      },
    ],
  },
  {
    version: "1.1.3",
    published_at: "May 24, 2023",
    fixesNumber: 1,
    year: "2023",
    points: [
      {
        description:
          "We've rectified the statistics for Replies to ensure accuracy and clarity.",
      },
    ],
  },
  {
    version: "1.1.2",
    published_at: "May 18, 2023",
    fixesNumber: 1,
    featuresNumber: 1,
    year: "2023",
    points: [
      {
        description:
          "We've fixed notable issues for an improved user experience.",
      },
      {
        description:
          "We're excited to announce the public release of our billing page.",
      },
    ],
  },
  {
    version: "1.1.1",
    published_at: "April 16, 2023",
    fixesNumber: 1,
    featuresNumber: 1,
    year: "2023",
    points: [
      { description: "We've updated our analytics to enhance reliability." },
    ],
  },
  {
    version: "1.1.0",
    published_at: "April 13, 2023",
    fixesNumber: 2,
    featuresNumber: 2,
    year: "2023",
    points: [
      {
        description:
          "We've ironed out the kinks, delivering a more streamlined UI experience.",
      },
      {
        description:
          "We've corrected the Analytics Data for our Chrome extension to ensure accurate tracking.",
      },
      {
        description:
          "We have introduced new features for integration with Pipedrive CRM to make the user experience more complete.",
      },
      {
        description:
          "Users can now conveniently switch between AI models in the settings.",
      },
    ],
  },
  {
    version: "1.0.0",
    published_at: "April 5, 2023",
    featuresNumber: 5,
    year: "2023",
    intro:
      "We're thrilled to introduce the inaugural release of our product, designed with precision and passion. Here are the standout features setting the tone for our journey ahead:",
    points: [
      {
        title: "Job Score / Job Rating:",
        description:
          "Extension analyzes Upwork job postings using AI to assist freelancers and companies in finding suitable projects faster. All job posts are rated based on the client’s and job’s history.",
      },
      {
        title: "AI-Powered Proposal Generator:",
        description:
          "Upwork proposal generator based on job post analysis. It helps freelancers and companies create professional proposals quickly, increasing their chances of successfully securing a project.",
      },
      {
        title: "AI-Powered Q&A:",
        description:
          "Technical response generation to job post questions on Upwork. It saves time communicating with potential clients and increases efficiency for freelancers and companies.",
      },
      {
        title: "Integration with Pipedrive CRM:",
        description:
          "Contact and message synchronization with Pipedrive CRM: Upwex.io allows the synchronization of contacts and messages with Pipedrive CRM, providing an easy integration between the two platforms.",
      },
      {
        title: "Analytics and Reporting:",
        description:
          "Analytics and reporting features for tracking project conversion and success. It helps freelancers and companies make data-driven decisions and improve their performance.",
      },
    ],
  },
];
