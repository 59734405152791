import PropTypes from 'prop-types';
import './plan-skeleton.scss';

interface IPlanSkeletonProps {
  isLoading: boolean;
  children: JSX.Element;
}

const baseClassName = 'plan-skeleton';

export const PlanSkeleton = ({ isLoading, children }: IPlanSkeletonProps): JSX.Element => {
  return isLoading ? (
    <div className={baseClassName}>
      <div className={`${baseClassName}__title`} />
      <div className={`${baseClassName}__price`} />
      <div className={`${baseClassName}__points`} />
      <div className={`${baseClassName}__button`} />
    </div>
  ) : children;
}

PlanSkeleton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};