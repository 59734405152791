import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import './requested-feature.scss';

const baseClass = 'requested-feature';

interface IRequestedFeatureProps {
  data: any
}

export const RequestedFeature = ({ data }: IRequestedFeatureProps): JSX.Element => {
  return (
    <div className={baseClass}>
      <h2 className={`${baseClass}__title`}>{data.title}</h2>
      <div className={`${baseClass}__control-icons`}>
        <span>
          <FaThumbsUp /> {data.upVotes}
        </span>
        <span>
          <FaThumbsDown /> {data.downVotes}
        </span>
      </div>
    </div>
  );
};