import React, { FocusEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IGetSettingsResponse,
  IResponseError,
  IJobSettings,
} from "../../../data-access";
import {
  FormInput,
  FormButton,
  FormSelect,
  FormTextarea,
  InformationBlock,
} from "../../../ui";
import { notify, handleFailedRequest } from "../../../util";
import "./job-settings-form.scss";
import { Divider } from "antd";
import { researcherApi } from "../../../api/job-researcher";
import { SearchFilters } from "../search-filters";

interface IJobSettingsFormProps {
  isFetching: boolean;
  settings: any;
  isStart?: boolean;
  onChange?: (value: any) => void;
}

const baseClass = "job-settings-form";
const availabilitySelectData = [
  {
    value: "More than 30 hrs/week",
    label: "More than 30 hrs/week",
  },
  {
    value: "Less than 30 hrs/week",
    label: "Less than 30 hrs/week",
  },
  {
    value: "As needed - open to offers",
    label: "As needed - open to offers",
  },
];

export const JobSettingsForm = ({
  isFetching,
  settings = {},
  isStart = false,
  onChange,
}: IJobSettingsFormProps): JSX.Element => {
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<string>("");
  const [profiles, setProfiles] = useState<any[]>([]);
  const [profilesSelectData, setProfilesSelectData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  const form = useForm<IJobSettings>({
    mode: "all",
    defaultValues: {
      login: settings.login,
      password: settings.password,
      secret_answer: settings.secret_answer || "",
      search_url:
        settings.search_url ||
        "https://www.upwork.com/nx/search/jobs/?per_page=50&sort=recency",
      name: settings.name || "",
      title_profile: settings.title_profile || "",
      description_profile: settings.description_profile || "",
      availability: settings.availability || "",
      skills: settings.skills || "",
      chat_id: settings.chat_id || "",
      channel_id: settings.channel_id || "",
      evaluation_criteria: settings.evaluation_criteria || "",
      filter: settings.filter || "",
    },
  });
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, dirtyFields, isValid },
  } = form;

  useEffect(() => {
    if (settings && settings.login) {
      reset({
        login: settings.login,
        password: settings.password,
        secret_answer: settings.secret_answer || "",
        search_url:
          settings.search_url ||
          "https://www.upwork.com/nx/search/jobs/?per_page=50&sort=recency",
        name: settings.name || "",
        title_profile: settings.title_profile || "",
        description_profile: settings.description_profile || "",
        availability: settings.availability || "",
        skills: settings.skills || "",
        chat_id: settings.chat_id || "",
        channel_id: settings.channel_id || "",
        evaluation_criteria: settings.evaluation_criteria || "",
        filter: settings.filter || "",
      });
    }
  }, [settings]);

  useEffect(() => {
    researcherApi.getUpworkProfiles().then((response: any) => {
      const users = response.data;
      setProfilesSelectData(
        users.map((user: any) => {
          return {
            value: user.userId,
            label: user.username,
          };
        })
      );
      setProfiles(users);
    });
  }, []);

  const handleJobIdChange = (availability: string): void => {
    setValue("availability", availability, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const handeChangeUrl = (url: string): void => {
    setValue("search_url", url, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    setOpen(false);
  };

  const getFormData = (formFields: IJobSettings) => {
    return {
      upwork: {
        login: formFields.login,
        password: formFields.password,
        secret_answer: formFields.secret_answer,
        search_url: formFields.search_url,
      },
      freelancer: {
        name: formFields.name,
        title_profile: formFields.title_profile,
        description_profile: formFields.description_profile,
        availability: formFields.availability,
        skills: formFields.skills,
      },
      telegram: {
        chat_id: formFields.chat_id,
      },
      slack: {
        slack_webhook_url: formFields.channel_id,
      },
      bot_task: {
        evaluation_criteria: formFields.evaluation_criteria,
        filter: formFields.filter,
      },
    };
  };

  const handlePasteInformation = (userId: string): void => {
    setSelectedProfile(userId);
    const selectedProfile = profiles.find(
      (user: any) => user.userId === userId
    );
    reset({
      login: form.getValues("login") || "",
      password: form.getValues("password") || "",
      secret_answer: form.getValues("secret_answer") || "",
      search_url: form.getValues("search_url") || "",
      name: selectedProfile.username || "",
      title_profile: selectedProfile.profileTitle || "",
      description_profile: selectedProfile.profileDescription || "",
      availability: selectedProfile.availability || "",
      skills: selectedProfile.skills.length
        ? selectedProfile.skills.join(", ")
        : "",
      chat_id: form.getValues("chat_id") || "",
      channel_id: form.getValues("channel_id") || "",
      evaluation_criteria: form.getValues("evaluation_criteria") || "",
      filter: form.getValues("filter") || "",
    });
  };

  const handleFormSubmit = (formFields: IJobSettings): void => {
    const requestForm = getFormData(formFields);
    setIsProcessingForm(true);
    if (settings && settings.login) {
      researcherApi
        .updateJobResearcherTask(1, requestForm)
        .then((response: IGetSettingsResponse) => {
          onChange && onChange({ updated: true });
          notify("success", "Settings have been successfully updated.");
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text);
        })
        .finally(() => setIsProcessingForm(false));
    } else {
      researcherApi
        .createJobResearcherTask(requestForm)
        .then((response: IGetSettingsResponse) => {
          onChange && onChange({ updated: true });
          notify("success", "Settings have been successfully updated.");
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text);
        })
        .finally(() => setIsProcessingForm(false));
    }
  };

  const handleCheckCreds = (): void => {
    setIsChecking(true);
    researcherApi
      .checkJobResearcherTaskUpworkCreds(1, {
        login: form.getValues("login"),
        password: form.getValues("password"),
        security_answer: form.getValues("secret_answer"),
      })
      .then((response: any) => {
        if (response.data.status === "error") {
          notify("error", response.data.message);
        } else {
          notify("success", "Credentials have been successfully checked.");
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsChecking(false));
  };

  const handleOpenFilters = () => {
    setOpen(true);
  };

  const handleOpenUpworkFilters = () => {
    const url = form.getValues("search_url");
    window.open(url, "_blank");
  };

  const onClose = () => {
    setOpen(false);
  };

  const isFormBeenModified = Object.keys(dirtyFields).length > 0;

  return !isFetching ? (
    <form className={`${baseClass}`} onSubmit={handleSubmit(handleFormSubmit)}>
      <div className={`${baseClass}__container`}>
        <div className={`${baseClass}__content`}>
          <h2 className={`${baseClass}__title`}>Upwork Credentials</h2>
          <Divider />
          <InformationBlock>
            We highly recommend creating a new, empty profile exclusively for
            job post searching. This profile does not need to be verified and
            should not have two-factor authentication enabled. We strongly
            advise against using your personal profiles for job search
            automation. You can learn more about how to create a new profile and
            why it’s better not to use your personal one{" "}
            <a
              href="https://help.upwex.io/en/articles/overview-ai-job-researcher/#faq"
              target="_blank"
            >
              here
            </a>
            .
          </InformationBlock>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>Login</h2>
              <FormInput
                {...register("login", {
                  required: "This field is required",
                })}
                value={watch("login")}
                disabled={isStart || isChecking}
                error={errors?.login?.message}
                placeholder="Example: upwex@gmail.com"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("login", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>Password</h2>
              <FormInput
                {...register("password", {
                  required: "This field is required",
                })}
                value={watch("password")}
                disabled={isStart || isChecking}
                error={errors?.password?.message}
                placeholder="Example: 123456"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("password", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
          </div>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Answer for Question
              </h2>
              <FormInput
                {...register("secret_answer", {
                  required: "This field is required",
                })}
                value={watch("secret_answer")}
                disabled={isStart || isChecking}
                error={errors?.secret_answer?.message}
                placeholder="Example: Upwex"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("secret_answer", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item pt-space`}>
              <FormButton
                loading={isChecking}
                onClick={handleCheckCreds}
                disabled={isChecking || isStart}
              >
                Check Upwork Credentials
              </FormButton>
            </div>
          </div>
        </div>

        <div className={`${baseClass}__content`}>
          <h2 className={`${baseClass}__title`}>Upwork Search Filters</h2>
          <Divider />
          <InformationBlock>
            Please configure a filter similar to Upwork’s filters. The bot will
            use this filter to browse job posts, and Upwex AI will evaluate each
            post based on your prompt. The better you set up the filter, the
            more accurate the bot’s selection will be. We highly recommend
            paying close attention to this step for the best results.
          </InformationBlock>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item centered`}>
              <FormButton onClick={handleOpenFilters} disabled={isStart}>
                Change Filters
              </FormButton>
              <FormButton onClick={handleOpenUpworkFilters} disabled={isStart}>
                Check Filters on Upwork
              </FormButton>
            </div>
          </div>
        </div>

        <div className={`${baseClass}__content`}>
          <h2 className={`${baseClass}__title`}>Upwork Profile Information</h2>
          <Divider />
          <InformationBlock>
            This information must be filled out according to your profile to
            compare the relevance of job posts to your specific experience. The
            more accurately this information reflects your skills and
            preferences, the more closely job posts will match your needs. These
            fields directly influence the final decision made by Upwex AI.
          </InformationBlock>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Synced Upwork Profiles
              </h2>
              <FormSelect
                disabled={isStart}
                placeholder="Please select job id"
                value={selectedProfile}
                options={profilesSelectData}
                onChange={handlePasteInformation}
              />
            </div>
            <div className={`${baseClass}__row-item pt-small-space`}>
              <InformationBlock>
                If you’ve synced your profile with Extension, you can select it{" "}
                <a
                  href="https://help.upwex.io/en/articles/sync-upwork-profile/"
                  target="_blank"
                >
                  here
                </a>
                .
              </InformationBlock>
            </div>
          </div>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>Name</h2>
              <FormInput
                {...register("name", {
                  required: "This field is required",
                })}
                value={watch("name")}
                disabled={isStart}
                error={errors?.name?.message}
                placeholder="Example: User User"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("name", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>Profile Title</h2>
              <FormInput
                {...register("title_profile", {
                  required: "This field is required",
                })}
                value={watch("title_profile")}
                disabled={isStart}
                error={errors?.title_profile?.message}
                placeholder="Example: WordPress Developer"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("title_profile", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
          </div>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Profile Description
              </h2>
              <FormTextarea
                {...register("description_profile", {
                  required: "This field is required",
                })}
                value={watch("description_profile")}
                disabled={isStart}
                placeholder="Example: I am a WordPress Developer..."
                error={errors?.description_profile?.message}
                onChange={(e: FocusEvent<HTMLTextAreaElement>) =>
                  setValue("description_profile", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>Skills</h2>
              <FormInput
                {...register("skills", {
                  required: "This field is required",
                })}
                value={watch("skills")}
                disabled={isStart}
                error={errors?.skills?.message}
                placeholder="Example: WordPress, WordPress Customization, WordPress Theme"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("skills", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
              />
            </div>
          </div>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>Availability</h2>
              <FormSelect
                {...register("availability", {
                  required: "This field is required",
                })}
                value={watch("availability")}
                disabled={isStart}
                placeholder="Please select job id"
                error={errors?.availability?.message}
                options={availabilitySelectData}
                onChange={handleJobIdChange}
              />
            </div>
          </div>
        </div>

        <div className={`${baseClass}__content`}>
          <h2 className={`${baseClass}__title`}>Notification Integration</h2>
          <Divider />
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Telegram Chat ID (Optional)
              </h2>
              <FormInput
                {...register("chat_id")}
                value={watch("chat_id")}
                disabled={isStart}
                placeholder="Example: -1234567890123"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("chat_id", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: false,
                    shouldTouch: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item pt-small-space`}>
              <InformationBlock>
                To receive notifications in Telegram, you need to add your
                Telegram Chat ID. You can quickly get your Chat ID using our
                bot,{" "}
                <a href="https://t.me/UpwexAIBot" target="_blank">
                  @UpwexAIBot
                </a>
                . You can choose to receive notifications either directly in the
                bot or by adding the bot to a group you’ve created. The
                notifications you receive will only include those that the bot
                has marked as relevant to you. For more details, you can read{" "}
                <a
                  href="https://help.upwex.io/en/articles/telegram-integration/"
                  target="_blank"
                >
                  here
                </a>
                .
              </InformationBlock>
            </div>
          </div>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Slack Channel Webhook (Optional)
              </h2>
              <FormInput
                {...register("channel_id")}
                value={watch("channel_id")}
                disabled={isStart}
                placeholder="https://hooks.slack.com/services/<WORKSPACE_ID>/<CHANNEL_ID>/<UNIQUE_TOKEN>"
                onChange={(e: FocusEvent<HTMLInputElement>) =>
                  setValue("channel_id", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: false,
                    shouldTouch: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item pt-small-space`}>
              <InformationBlock>
                You can also receive notifications in Slack. To set up this
                integration, follow the instructions provided{" "}
                <a
                  href="https://help.upwex.io/en/articles/slack-integration/"
                  target="_blank"
                >
                  here
                </a>
                .
              </InformationBlock>
            </div>
          </div>
        </div>

        <div className={`${baseClass}__content`}>
          <h2 className={`${baseClass}__title`}>AI Prompt</h2>
          <Divider />
          <InformationBlock>
            AI Prompt is one of the most <strong>important</strong> elements of
            the AI bot. Using the <strong>Evaluation Criteria</strong>{" "}
            parameter, the bot assesses how relevant the jobs are to your query.
            The <strong>Important Filter</strong> is the filter through which
            the selection process is carried out. You can find more details on
            how this works, along with examples,{" "}
            <a
              href="https://help.upwex.io/en/articles/settings-ai-prompt/"
              target="_blank"
            >
              here
            </a>
            .
          </InformationBlock>
          <div className={`${baseClass}__row`}>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Evaluation Criteria
              </h2>
              <FormTextarea
                {...register("evaluation_criteria", {
                  required: "This field is required",
                })}
                maxLength={5000}
                value={watch("evaluation_criteria")}
                disabled={isStart}
                placeholder="Example: Consider the number of invitations as a risk that the client may already..."
                error={errors?.evaluation_criteria?.message}
                onChange={(e: FocusEvent<HTMLTextAreaElement>) =>
                  setValue("evaluation_criteria", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </div>
            <div className={`${baseClass}__row-item`}>
              <h2 className={`${baseClass}__section-title`}>
                Important Filter
              </h2>
              <FormTextarea
                {...register("filter", {
                  required: "This field is required",
                })}
                maxLength={5000}
                value={watch("filter")}
                disabled={isStart}
                placeholder="Example: Filter job posts that specifically mention WordPress development, including..."
                error={errors?.filter?.message}
                onChange={(e: FocusEvent<HTMLTextAreaElement>) =>
                  setValue("filter", e?.target?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <FormButton
        className={`${baseClass}__button`}
        htmlType="submit"
        loading={isProcessingForm || isFetching}
        disabled={
          !isFormBeenModified || !isValid || isProcessingForm || isStart
        }
      >
        Save
      </FormButton>
      <SearchFilters
        open={open}
        onClose={onClose}
        onSave={handeChangeUrl}
        searchUrl={form.getValues("search_url")}
      />
    </form>
  ) : (
    <></>
  );
};

export default JobSettingsForm;
