import PropTypes from 'prop-types';
import { ReactNode } from 'react';
import { RequestedFeaturesStatusIndicatorColor, RequestedFeatureStatusTitle } from '../../../data-access';
import './requested-feature-column.scss';

const baseClass = 'requested-feature-column';

interface IRequestedFeatureColumnProps {
  title: RequestedFeatureStatusTitle,
  indicatorColor: RequestedFeaturesStatusIndicatorColor;
  children: ReactNode,
}

export const RequestedFeatureColumn = ({ children, indicatorColor, title }: IRequestedFeatureColumnProps): JSX.Element => {
  return (
    <div className={baseClass}>
      <div className={`${baseClass}__heading`}>
        <span className={`${baseClass}__status`} style={{ background: indicatorColor }} />
        <h3 className={`${baseClass}__title`}>{title}</h3>
      </div>
      <div className={`${baseClass}__content`}>
        {children}
      </div>
    </div>
  );
}

RequestedFeatureColumn.propTypes = {
  title: PropTypes.oneOf(['Under Consideration', 'Up Next', 'In Progress', 'Shipped']).isRequired,
  indicatorColor: PropTypes.oneOf(['orange', 'green', 'blue', 'red']).isRequired,
  children: PropTypes.node.isRequired,
};