import React from "react";
import { Offer } from "./offer";
import "./special-offers.scss";

const items = [
  {
    id: 1,
    title: "100 ai requests",
    subtitle: "for feedback in Chrome Store",
    items: [
      {
        id: 1,
        html: `<p>1. Go to <a href="https://chromewebstore.google.com/detail/upwex-ai-tools-for-upwork/pmipgiahphnifpajbfnpahjfkanpfabf/reviews" target="_blank">Chrome Store</a>.</p>`,
      },
      {
        id: 2,
        html: "<p>2. Leave feedback.</p>",
      },
      {
        id: 3,
        html: "<p>3. Send feedback screen and email of the user (who is registered in Upwex).</p><span>*User must have a paid subscription</span>",
      },
      {
        id: 4,
        html: "<p>4. Wait for confirmation from the administrator.</p>",
      },
      {
        id: 5,
        html: "<p>5. Congratulations! You are credited with 100 free AI Requests.</p>",
      },
    ],
  },
  // {
  //   id: 2,
  //   title: "10% discount",
  //   subtitle: "for an invited friend",
  //   items: [
  //     {
  //       id: 1,
  //       html: `<p>1. Go to <a href="https://chromewebstore.google.com/detail/upwex-ai-tools-for-upwork/pmipgiahphnifpajbfnpahjfkanpfabf/reviews" target="_blank">Chrome Store</a>.</p>`,
  //     },
  //     {
  //       id: 2,
  //       html: "<p>2. Leave feedback.</p>",
  //     },
  //     {
  //       id: 3,
  //       html: "<p>3. Send feedback screen and email of the user (who is registered in Upwex).</p><span>*User must have a paid subscription</span>",
  //     },
  //     {
  //       id: 4,
  //       html: "<p>4. Wait for confirmation from the administrator.</p>",
  //     },
  //     {
  //       id: 5,
  //       html: "<p>5. Congratulations! You are credited with 100 free AI Requests.</p>",
  //     },
  //   ],
  // },
];

export const SpecialOffers = (): JSX.Element => {
  return (
    <div className="special-offers">
      {items.map((item) => (
        <Offer
          key={item.id}
          title={item.title}
          subtitle={item.subtitle}
          items={item.items}
        ></Offer>
      ))}
    </div>
  );
};

export default SpecialOffers;
