import { storage } from "../storage";
import { store } from "../../store";
import { cookie, encryption, uuidStorageKey } from "..";

export const updateExtensionCookies = (data: any): void => {
  const authDetails = store.getState().auth;

  cookie.set(
    "extFlag",
    encryption.generateHash(
      JSON.stringify({
        deviceUUID: storage.get(uuidStorageKey),
        access_token: authDetails.access_token,
        refresh_token: authDetails.refresh_token,
        user: {
          id: data.user.id,
          paid_user: data.user.paid_user,
          user: data.user.id,
          email: data.user.email,
          license: data.user.license,
        },
      })
    )
  );
  // cookie.set(
  //   "extFlag",
  //   encryption.generateHash(
  //     JSON.stringify({
  //       deviceUUID: storage.get(uuidStorageKey),
  //       access_token: authDetails.access_token,
  //       refresh_token: authDetails.refresh_token,
  //       ...data,
  //     })
  //   )
  // );
};
