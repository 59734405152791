import PropTypes from "prop-types";
import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { billingApi } from "../../api";
import { appConfig } from "../../app-config";
import { IResponseError } from "../../data-access";
import { ModalDialog } from "../../ui";
import { handleFailedRequest } from "../../util";
import "./buy-extra-requests-modal.scss";

interface IBuyExtraRequestsModalProps {
  onClose: () => void;
  licenseId?: string;
}

const baseClassName = "buy-extra-requests-modal";

export const BuyExtraRequestsModal = ({
  onClose,
  licenseId = "",
}: IBuyExtraRequestsModalProps): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [requestsNumberToPurchase, setRequestsNumberToPurchase] =
    useState<number>(50);

  const increaseRequestsNumber = (): void => {
    if (isProcessing) {
      return;
    }
    setRequestsNumberToPurchase(requestsNumberToPurchase + 50);
  };

  const decreaseRequestsNumber = (): void => {
    if (isProcessing) {
      return;
    }

    if (requestsNumberToPurchase > 50) {
      setRequestsNumberToPurchase(requestsNumberToPurchase - 50);
    }
  };

  const handlePurchaseAdditionalRequests = (): void => {
    if (!licenseId) return;

    setIsProcessing(true);

    billingApi
      .purchaseAdditionalRequests(requestsNumberToPurchase, licenseId)
      .then((response: { data: { paymentLink: string } }) => {
        window.location.href = response.data.paymentLink;
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessing(false));
  };

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  };

  return (
    <ModalDialog
      title="Buy Extra AI Requests"
      enableActionButtons={true}
      confirmText={`Add ${requestsNumberToPurchase} requests`}
      isOpen={true}
      confirmLoading={isProcessing}
      onConfirm={handlePurchaseAdditionalRequests}
      onCancel={handleClose}
      closable={false}
    >
      <p className={`${baseClassName}__subtitle`}>
        You can buy additional requests for selected license.
      </p>
      <div className={`${baseClassName}__counter`}>
        <div
          className={`${baseClassName}__section column`}
          onClick={decreaseRequestsNumber}
        >
          <FaMinus />
        </div>
        <div className={`${baseClassName}__section column`}>
          {requestsNumberToPurchase}
        </div>
        <div
          className={`${baseClassName}__section column`}
          onClick={increaseRequestsNumber}
        >
          <FaPlus />
        </div>
      </div>
      <strong className={`${baseClassName}__order-notice`}>
        You will add: {requestsNumberToPurchase} AI requests, price:{" "}
        {(requestsNumberToPurchase / 50) *
          appConfig.priceForAdditional50Requests}{" "}
        USD
      </strong>
    </ModalDialog>
  );
};

BuyExtraRequestsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  licenseId: PropTypes.string,
};
