import { baseApiPath, deleteEntity, get, post } from "./base-api";
import {
  ICheckAccessCodeParams,
  IRegisterParams,
  IRefreshTokenParams,
  UserStatus,
  ITeamMembersResponse,
  IAnalyticsResponse,
  IUser,
  IRefreshTokenResponse,
  IAuthResponse,
  AnalyticsPeriod,
  IProfilesAnalyticsResponse,
  ProfileFilterCriteriaType,
  IGetProposalsResponse,
} from "../data-access";
import { querify } from "../util";

const authenticate = (email: string): Promise<{ data: string }> => {
  return post(`${baseApiPath}/api/auth/login`, { email });
};

const googleAuthenticate = (
  id_token: string | undefined
): Promise<IAuthResponse> => {
  return post(`${baseApiPath}/api/auth/google-auth`, { id_token });
};

const checkAccessCodeValidity = ({
  email,
  access_code,
}: ICheckAccessCodeParams): Promise<IAuthResponse> => {
  return post(`${baseApiPath}/api/auth/access-code-check`, {
    email,
    access_code,
  });
};

const register = ({
  email,
  picture,
  full_name,
}: IRegisterParams): Promise<{ data: string }> => {
  return post(`${baseApiPath}/api/auth/register`, {
    email,
    picture,
    full_name,
  });
};

const refreshToken = ({
  refresh_token,
}: IRefreshTokenParams): Promise<{ data: IRefreshTokenResponse }> => {
  return post<IRefreshTokenParams, { data: IRefreshTokenResponse }>(
    `${baseApiPath}/api/auth/refresh`,
    { refresh_token }
  );
};

const getMe = (): Promise<{ data: IUser }> => {
  return get<{ data: IUser }>(`${baseApiPath}/api/user`);
};

const getTeamMembers = (): Promise<ITeamMembersResponse> => {
  return get<ITeamMembersResponse>(`${baseApiPath}/api/team/users`);
};

const inviteTeamMember = (email: string): Promise<{ data: string }> => {
  return post<{ email: string }, { data: string }>(
    `${baseApiPath}/api/team/invite`,
    { email }
  );
};

const resendInvitation = (email: string): Promise<{ data: string }> => {
  return post<{ email: string }, { data: string }>(
    `${baseApiPath}/api/team/resendInvite`,
    { email }
  );
};

const deleteUser = (id: string, isHard: boolean): Promise<string> => {
  const params = isHard ? "?wipeData=1" : "";
  return deleteEntity<string>(`${baseApiPath}/api/team/${id}/delete${params}`);
};

const changeRole = (id: string, role: UserStatus): Promise<{ data: IUser }> => {
  return post<{ role: string }, { data: IUser }>(
    `${baseApiPath}/api/team/${id}/changeRole`,
    { role }
  );
};

const assignLicense = (
  userId: string,
  id: string
): Promise<{ data: IUser }> => {
  return post<{}, { data: IUser }>(
    `${baseApiPath}/api/team/${userId}/assignLicense/${id}`,
    {}
  );
};

const unAssignLicense = (userId: string): Promise<{ data: IUser }> => {
  return post<{}, { data: IUser }>(
    `${baseApiPath}/api/team/${userId}/unassignLicense`,
    {}
  );
};

const getUserAnalytics = ({
  id,
  period,
}: {
  id: string;
  period: AnalyticsPeriod;
}): Promise<{ data: IAnalyticsResponse }> => {
  const userIdParam = id ? `&user_id=${id}` : "";
  return get<{ data: IAnalyticsResponse }>(
    `${baseApiPath}/api/insights/get-insights?time_period=${period}${userIdParam}`
  );
};

const getAnalyticsForProfiles = (
  filterPeriod: ProfileFilterCriteriaType
): Promise<IProfilesAnalyticsResponse> => {
  const queryParams = filterPeriod ? `?time_period=${filterPeriod}` : "";
  return get(
    `${baseApiPath}/api/insights/get-insights-for-uprofiles${queryParams}`
  );
};

const getAnalyticsForProfilesByDate = (
  params: string
): Promise<IProfilesAnalyticsResponse> => {
  const queryParams = params ? `?${params}` : "";
  return get(
    `${baseApiPath}/api/insights/get-insights-for-uprofiles${queryParams}`
  );
};

const fullyLogoutUser = (): Promise<void> => {
  return post<{}, void>(`${baseApiPath}/api/auth/logout`, {});
};

const getProposals = (args: {
  upwork_user_id: string;
  upwex_user_id: string;
  page: string;
  per_page: number;
  order_by?: string;
  time_period?: ProfileFilterCriteriaType;
  date_from?: string;
  date_to?: string;
}): Promise<IGetProposalsResponse> => {
  const params = querify(args);
  return get(`${baseApiPath}/api/insights/proposals?${params}`);
};

export const usersApi = {
  authenticate,
  googleAuthenticate,
  checkAccessCodeValidity,
  register,
  refreshToken,
  getMe,
  getTeamMembers,
  inviteTeamMember,
  resendInvitation,
  deleteUser,
  changeRole,
  assignLicense,
  unAssignLicense,
  getUserAnalytics,
  fullyLogoutUser,
  getAnalyticsForProfiles,
  getAnalyticsForProfilesByDate,
  getProposals,
};
