import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { FaRobot } from "react-icons/fa";
import "./offer.scss";
import { IOfferItem } from "../offer.interface";

interface IOfferProps {
  title: string;
  subtitle?: string;
  items?: IOfferItem[];
}

export const Offer = ({
  title,
  subtitle,
  items = [],
}: IOfferProps): JSX.Element => {
  return (
    <div className="offer__container">
      <div className="offer__content">
        <div className="offer__content-info">
          <h2 className="offer__title">{title}</h2>
          <h2 className="offer__subtitle">{subtitle}</h2>
        </div>
      </div>
      <div className="offer__details">
        <h2 className="offer__desc">Details:</h2>
        <div className="offer__details-content">
          {items.map((item) => (
            <div
              key={item.id}
              className="offer__details-item"
              dangerouslySetInnerHTML={{ __html: item.html }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

Offer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  items: PropTypes.array,
};
