import PropTypes from 'prop-types';
import './manage-seats.scss';

interface IManageSeatsProps {
  currentNumber: number;
};

export const ManageSeats = ({ currentNumber }: IManageSeatsProps): JSX.Element => {
  return (
    <div className="manage-seats">
      <p className="caption">You can add paid licences for your account or your team.</p>
      <h2 className="form-title">Current number of licences: <strong>{currentNumber}</strong></h2>
      <p className="billing-note">You will be billed for adding a new licence after submitting. Are you sure you want to add a new licence?</p>
    </div>
  );
}

ManageSeats.propTypes = {
  currentNumber: PropTypes.number.isRequired
};