import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ManageSeats } from "./manage-seats";
import { billingApi } from "../../api";
import { APP_ROUTES, ILicence, IResponseError } from "../../data-access";
import { useUser } from "../../hooks";
import { RootState } from "../../store";
import { storeBillingActions } from "../../store/slices/billing";
import { storeModalsActions } from "../../store/slices/modals";
import { ModalDialog } from "../../ui";
import { handleFailedRequest, notify } from "../../util";
import { useNavigate } from "react-router-dom";

interface IPurchaseLicencesModalProps {
  onClose: () => void;
  onSuccess?: () => void;
}

export const PurchaseLicencesModal = ({
  onClose,
  onSuccess,
}: IPurchaseLicencesModalProps): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const licences = useSelector((state: RootState) => state.billing.licences);
  const { currentUser } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToBilling = (): void => {
    navigate(APP_ROUTES.billing);
  };

  const handlePay = (id: string): void => {
    setIsProcessing(true);

    billingApi
      .getPaymentLink(id)
      .then((response: { data: { payment_url: string } }) => {
        if (response.data.payment_url) {
          window.location.href = response.data.payment_url;
        } else {
          notify(
            "error",
            "Payment Error: Please contact our support team via the chat."
          );
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handlePurchaseConfirmation = (): void => {
    setIsProcessing(true);

    billingApi
      .createBillingLicence()
      .then((response: { data: ILicence }) => {
        dispatch(
          storeBillingActions.update({
            licences: [response.data, ...licences],
          })
        );

        onSuccess && onSuccess();
        notify("success", "Subscription has been successfully purchased.");
        redirectToBilling();
        handlePay(response.data.id);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
        dispatch(storeModalsActions.reset());
      });
  };

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  };

  return (
    <ModalDialog
      title="Add licences"
      enableActionButtons={true}
      isOpen={true}
      confirmText="Yes, buy 1 licence"
      confirmLoading={isProcessing}
      onConfirm={handlePurchaseConfirmation}
      onCancel={handleClose}
    >
      <ManageSeats currentNumber={Number(currentUser?.active_licenses_count)} />
    </ModalDialog>
  );
};

PurchaseLicencesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};
