import PropTypes from 'prop-types';
import { ReactNode } from 'react';
import { FaTimes } from 'react-icons/fa';
import './major-notification.scss';

interface IMajorNotification {
  children: ReactNode | string;
  theme?: 'success' | 'error' | 'technical';
  showIcon?: boolean;
  closable?: boolean;
  onClose?: () => void;
}

const baseClass = 'release-notification';

export const MajorNotification = ({
  children,
  theme = 'success',
  showIcon = true,
  closable = false,
  onClose
}: IMajorNotification): JSX.Element => {
  return (
    <div className={`${baseClass} ${theme}`}>
      <div className={`${baseClass}__paragraph`}>
        {showIcon && (
          <img
            data-mce-resize="false"
            data-mce-placeholder="1" data-wp-emoji="1"
            className="emoji" alt="⏳"
            src="/time-icon.svg"
          />
        )}
        {children}
        {(closable && onClose) && <button className="close-button" onClick={onClose}><FaTimes /></button>}
      </div>
    </div>
  );
}

MajorNotification.propTypes = {
  children: PropTypes.node.isRequired,
  showIcon: PropTypes.bool,
  theme: PropTypes.oneOf(['success', 'error', 'technical'])
};