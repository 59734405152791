import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTrashAlt, FaCheck, FaBan } from 'react-icons/fa';
import {
  IRequestedFeature,
  RequestedFeaturesStatusIndicatorColor,
  RequestedFeatureStatusTitle
} from '../../../data-access';
import { FormButton } from '../../../ui';
import { RequestedFeature } from '../requested-feature';
import { RequestedFeatureColumn } from '../requested-feature-column';
import './reorderable-requested-feature.scss';

const requestedFeaturesColumns = [
  { title: 'Under Consideration', color: RequestedFeaturesStatusIndicatorColor.under_consideration },
  { title: 'Up Next', color: RequestedFeaturesStatusIndicatorColor.up_next },
  { title: 'In Progress', color: RequestedFeaturesStatusIndicatorColor.in_progress },
  { title: 'Shipped', color: RequestedFeaturesStatusIndicatorColor.shipped },
];

const reorder = (list: IRequestedFeature[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {} as any;
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'rgba(60, 179, 113, 1)' : 'rgba(19, 84, 78, .1)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : '#f7f8fa',
  padding: grid,
  width: 250
});

const baseClass = 'reorderable-requested-feature';

export const ReorderableRequestedFeatures = ({ features }: any): JSX.Element => {
  const [state, setState] = useState<any>(features);
  const [featureToDeleteData, setFeatureToDeleteData] = useState<{ featureId: string; columnIndex: number; } | null>(null);

  function onDragEnd(result: any) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd] as any, source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items as any;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
    }
  }

  const handleFeatureDeleteConfirm = (featureId: string, columnIndex: number): void => {
    const newState = [...state];
    const targetFeatureIndex = newState[columnIndex].findIndex((feature: any) => feature.id === featureId);
    newState[columnIndex].splice(targetFeatureIndex, 1);
    setState(newState);
    setFeatureToDeleteData(null);
  }

  const handleFeatureDeleteClick = (featureId: string, columnIndex: number): void => {
    setFeatureToDeleteData({
      featureId,
      columnIndex
    });
  }

  return (
    <div className={baseClass}>
      <DragDropContext onDragEnd={onDragEnd}>
        {state.length > 0 && state.map((el: IRequestedFeature[], ind: number) => {
          return (
            <RequestedFeatureColumn key={`column-${ind}`} title={Object.values(requestedFeaturesColumns)[ind].title as RequestedFeatureStatusTitle} indicatorColor={Object.values(requestedFeaturesColumns)[ind].color as RequestedFeaturesStatusIndicatorColor}>
              <Droppable key={`droppable-${ind}`} droppableId={`${ind}`}>
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {el.map((item: any, index: number) => (
                      <Draggable
                        key={`draggable-${ind}-${item.id}`}
                        draggableId={item?.id}
                        index={index}
                      >
                        {(provided: any, snapshot: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${baseClass}__draggable-feature`}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <RequestedFeature data={item} />
                            {featureToDeleteData?.featureId !== item.id && (
                              <FaTrashAlt onClick={() => handleFeatureDeleteClick(item.id, ind)} />
                            )}
                            {featureToDeleteData && featureToDeleteData.featureId === item.id && (
                              <div className={`${baseClass}__confirmation-buttons`}>
                                <FormButton theme="green" onClick={() => {
                                  if (featureToDeleteData) {
                                    handleFeatureDeleteConfirm(featureToDeleteData.featureId, featureToDeleteData.columnIndex)
                                  }
                                }}>
                                  <FaCheck /> Confirm
                                </FormButton>
                                <FormButton theme="red" onClick={() => {
                                  setFeatureToDeleteData(null);
                                }}>
                                  <FaBan /> Reject
                                </FormButton>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </RequestedFeatureColumn>
          )
        })}
      </DragDropContext>
    </div>
  );
}