import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appConfig } from "../../app-config";
import { useMobileDevice } from "../../hooks";
import { storeGenericActions } from "../../store/slices/generic";
import { FormSelect } from "../../ui";
import { cookie } from "../../util";
import { IRelease, releasesData } from "./releases-data";
import "./release-notes.scss";

const baseClass = "release-notes";

interface IReleaseAccordionProps {
  title: string;
  releases: IRelease[];
  isInitiallyOpen: boolean;
  onChange: (release: IRelease) => void;
}

const releasesYears = [
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
];

const ReleaseAccordion = ({
  isInitiallyOpen,
  title,
  releases,
  onChange,
}: IReleaseAccordionProps): JSX.Element => {
  const [releaseToShow, setReleaseToShow] = useState<IRelease>(releases[0]);
  const [toggleMore, setToggleMore] = useState<boolean>(false);
  const [selectedReleasesYear, setSelectedReleasesYear] = useState<string>(
    `${2024}`
  );
  const [releasesBySelectedYear, setReleasesBySelectedYear] = useState<
    IRelease[]
  >([]);
  const [releaseSelectorOptions, setReleaseSelectorOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const isMobileResolution = useMobileDevice();

  const handleShowMoreReleasesClick = (): void => {
    setToggleMore(!toggleMore);
  };

  const handleReleaseSelection = (version: string): void => {
    const targetRelease = releases.find(
      (release) => release.version === version
    );
    if (targetRelease) {
      setReleaseToShow(targetRelease);
    }
  };

  useEffect(() => {
    onChange(releaseToShow);
  }, [releaseToShow, onChange]);

  useEffect(() => {
    const filteredReleases = releases.filter(
      (release: IRelease) => release.year === selectedReleasesYear
    );
    const releasesSelectorOptions = filteredReleases.map(
      (release: IRelease) => ({
        label: `${release.version} (${release.published_at})`,
        value: release.version,
      })
    );
    setReleaseSelectorOptions(releasesSelectorOptions);
    setReleasesBySelectedYear(filteredReleases);
    setReleaseToShow(filteredReleases[0]);
  }, [selectedReleasesYear]);

  return (
    <div className={`releases-accordion ${toggleMore ? "show-more" : ""}`}>
      <div className={`${baseClass}__release-panel`}>
        {title} ({releasesBySelectedYear.length} releases)
      </div>
      <FormSelect
        value={selectedReleasesYear}
        options={releasesYears}
        onChange={setSelectedReleasesYear}
      />
      {isMobileResolution && (
        <FormSelect
          value={releaseToShow.version}
          options={releaseSelectorOptions}
          onChange={handleReleaseSelection}
        />
      )}
      {!isMobileResolution && (
        <>
          <ul
            className={`${baseClass}__releases-list ${
              isInitiallyOpen ? "expanded" : ""
            }`}
          >
            {releasesBySelectedYear.map((release: IRelease) => (
              <li
                key={release.version}
                className={`${baseClass}__release ${
                  releaseToShow.version === release.version ? "active" : ""
                }`}
                onClick={() => setReleaseToShow(release)}
              >
                <span className={`${baseClass}__release-version`}>
                  {release.version}
                </span>
                <span className={`${baseClass}__publish-time`}>
                  {release.published_at}
                </span>
              </li>
            ))}
          </ul>
          {releasesBySelectedYear.length > 15 ? (
            <div className={`${baseClass}__show-more-releases`}>
              <button onClick={handleShowMoreReleasesClick}>
                {!toggleMore ? "Show more" : "Show less"}
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export const ReleaseNotes = (): JSX.Element => {
  const [releaseToShow, setReleaseToShow] = useState<IRelease>({} as IRelease);

  const dispatch = useDispatch();

  useEffect(() => {
    cookie.set("newReleaseChecked", appConfig.version);
    dispatch(storeGenericActions.update({ checkedRelease: appConfig.version }));
  }, [dispatch]);

  const getFixesShareInPx = (): number => {
    const fixes = releaseToShow?.fixesNumber || 0;
    const features = releaseToShow?.featuresNumber || 0;
    const indicatorWidthInPx = 90;

    const numberConsideredAs100percents = fixes + features;

    return numberConsideredAs100percents > 0
      ? Math.ceil(
          (Math.ceil((fixes * 100) / numberConsideredAs100percents) / 100) *
            indicatorWidthInPx
        )
      : numberConsideredAs100percents;
  };

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__main-content`}>
        <div className="column column--left">
          <h3 className={`${baseClass}__title`}>
            <strong>{releasesData.length}</strong> releases
          </h3>
          <ReleaseAccordion
            isInitiallyOpen={true}
            onChange={setReleaseToShow}
            title="This year"
            releases={releasesData}
          />
        </div>
        <div className="column column--right">
          <h2 className={`${baseClass}__release-major-title`}>
            <strong>{releaseToShow?.version}</strong>
          </h2>
          <div className="row">
            <div className={`${baseClass}__author`}>
              <span className={`${baseClass}__time-ago-since-published`}>
                released <strong>{releaseToShow?.published_at}</strong>
              </span>
            </div>
            <Tooltip
              title="Number of fixes(🔴) and features(🟢) in current release"
              color="#fff"
              key="fixes-features-tooltip"
            >
              <div className={`${baseClass}__indicator`}>
                <span
                  className={`${baseClass}__fixes-share`}
                  style={{ minWidth: getFixesShareInPx() }}
                />
                <div className={`${baseClass}__indicator-fix-features`}>
                  {releaseToShow?.fixesNumber && (
                    <span
                      className={`${baseClass}__indicator-value fixes`}
                      style={{ left: getFixesShareInPx() - 12 }}
                    >
                      {releaseToShow?.fixesNumber}-
                    </span>
                  )}
                  {releaseToShow?.featuresNumber && (
                    <span className={`${baseClass}__indicator-value features`}>
                      {releaseToShow.featuresNumber}+
                    </span>
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
          <div className={`${baseClass}__release-details column`}>
            <div className={`${baseClass}__release-heading`}>Release note</div>
            {releaseToShow?.intro && (
              <span className={`${baseClass}__intro`}>
                {releaseToShow.intro}
              </span>
            )}
            {releaseToShow?.points?.length > 0 && (
              <ul>
                {releaseToShow.points.map((point, index) => {
                  return (
                    <li key={`point-${index}`}>
                      {point?.title && <strong>{point.title}</strong>}{" "}
                      {point.description}
                      {point?.images?.map((src: string) => (
                        <img src={src} alt={point.title} />
                      ))}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
