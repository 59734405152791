import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../data-access';
import './brand-logo.scss';

interface IBrandLogoProps {
  allowNavigation?: boolean;
}

export const BrandLogo = ({ allowNavigation = true }: IBrandLogoProps): JSX.Element => {
  const navigate = useNavigate();

  const handleLogoClick = (): void => {
    if (allowNavigation) {
      navigate(APP_ROUTES.login);
    }
  }

  return (
    <div className="brand-logo">
      <span className="brand-logo__avatar" onClick={handleLogoClick}>
        <img src="/upwex-logo.svg" alt="Upwex.io" />
      </span>
    </div>
  );
}

BrandLogo.propTypes = {
  allowNavigation: PropTypes.bool,
};