import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
// @ts-ignore
import { ReactComponent as HappyFaceSVG } from "../../../../assets/images/happy-smile.svg";
// @ts-ignore
import { ReactComponent as SadFaceSVG } from "../../../../assets/images/sad-smile.svg";
import { billingApi } from "../../../../api";
import { ILicence, IResponseError } from "../../../../data-access";
import { RootState } from "../../../../store";
import { storeBillingActions } from "../../../../store/slices/billing";
import { FormButton, ModalDialog } from "../../../../ui";
import { handleFailedRequest, notify } from "../../../../util";
import "./subscription-cancellation-modal.scss";

interface ISubscriptionCancellationModalProps {
  onClose: () => void;
  licenseId?: string;
}

export const SubscriptionCancellationModal = ({
  onClose,
  licenseId,
}: ISubscriptionCancellationModalProps): JSX.Element => {
  const [isCloseIconHovered, setIsCloseIconHovered] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const availableLicences = useSelector(
    (state: RootState) => state.billing.licences
  );
  const dispatch = useDispatch();

  const toggleCloseIconHover = (): void => {
    setIsCloseIconHovered(!isCloseIconHovered);
  };

  const makeSubscriptionCanceled = (id: string): void => {
    const licencesList = [...availableLicences];
    const failedSubscriptionIndex = licencesList.findIndex(
      (sub: ILicence) => sub.id === id
    );
    licencesList[failedSubscriptionIndex] = {
      ...licencesList[failedSubscriptionIndex],
      payment_status: "canceled",
    };
    dispatch(
      storeBillingActions.update({
        licences: licencesList,
      })
    );
  };

  const handleCancellation = (): void => {
    if (licenseId) {
      setIsProcessing(true);

      billingApi
        .deleteBillingLicence(licenseId)
        .then(() => {
          makeSubscriptionCanceled(licenseId);
          notify("success", `Subscription has been successfully cancelled.`);
        })
        .catch((error: IResponseError) => {
          handleFailedRequest(error.status, error.text, error.details);
        })
        .finally(() => {
          setIsProcessing(false);
          onClose();
        });
    }
  };

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  };

  return (
    <ModalDialog
      title={
        <div className="row">
          Cancel licence{" "}
          <div className="svg-icon">
            {isCloseIconHovered ? <HappyFaceSVG /> : <SadFaceSVG />}
          </div>
        </div>
      }
      className="cancel-popup"
      confirmText="Cancel subscription"
      isOpen={true}
      closeIcon={
        <CloseOutlined
          onMouseEnter={toggleCloseIconHover}
          onMouseLeave={toggleCloseIconHover}
        />
      }
      footer={[
        <FormButton
          theme="red"
          disabled={isProcessing}
          loading={isProcessing}
          onClick={handleCancellation}
        >
          Cancel subscription
        </FormButton>,
      ]}
      onCancel={handleClose}
    >
      <p className="cancel-popup__body">
        <span className="cancel-popup__main-text">
          If you cancel your subscription, your license will be active until the
          end of the current billing period, so you can still enjoy your license
          till its last day.
        </span>
        <span className="cancel-popup__secondary-text">
          If the reason for canceling is a technical issue, please get in touch
          with our Support Team. We'll be glad to have your feedback and do our
          best to resolve the issue.
        </span>
      </p>
    </ModalDialog>
  );
};

SubscriptionCancellationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  licenseId: PropTypes.string,
};
