import PropTypes from "prop-types";
import { WelcomeModalContent } from "./welcome-modal-content";
import { ModalDialog } from "../../ui";
import "./welcom-modal.scss";

interface IInviteTeamMembersModalProps {
  onClose: () => void;
}

export const WelcomeModal = ({
  onClose,
}: IInviteTeamMembersModalProps): JSX.Element => {
  const handleClose = (): void => {
    onClose();
  };

  return (
    <ModalDialog
      className="welcome-modal"
      title=""
      isOpen={true}
      onCancel={handleClose}
    >
      <WelcomeModalContent />
    </ModalDialog>
  );
};

WelcomeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
