import { useState } from 'react';
import PropTypes from 'prop-types';
import { InviteTeamMemberForm } from './invite-team-member-form';
import { ModalDialog } from '../../ui';
import { usersApi } from '../../api';
import { handleFailedRequest, notify } from '../../util';
import { IResponseError } from '../../data-access';

interface IInviteTeamMembersModalProps {
  onClose: () => void;
  onSuccess?: () => void;
}

export const InviteTeamMembersModal = ({ onClose, onSuccess }: IInviteTeamMembersModalProps): JSX.Element => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleFormSubmit = (formFields: { email: string }): void => {
    setIsProcessing(true);

    usersApi.inviteTeamMember(formFields.email)
      .then(() => {
        onClose();
        onSuccess && onSuccess();
        notify('success',  'Invitation has been successfully sent');
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessing(false));
  }

  const handleClose = (): void => {
    if (!isProcessing) {
      onClose();
    }
  }

  return (
    <ModalDialog
      className="invite-team-member-modal"
      title="Invite a new team member"
      isOpen={true}
      onCancel={handleClose}
    >
      <InviteTeamMemberForm isLoading={isProcessing} onSuccess={handleFormSubmit} />
    </ModalDialog>
  );
}

InviteTeamMembersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};