import { useEffect, useRef, useState } from 'react';

type IReturnType = {
  isInstalled: boolean | undefined;
  versionInstalled: string | undefined;
};

export const useExtensionDetails = (): IReturnType => {
  // default suggestion is that extension is already installed
  // then after extension sets cei class on body, app detects mutation and updates status
  // of extension notification
  const [isInstalled, setIsInstalled] = useState<boolean | undefined>(undefined);
  const [versionInstalled, setVersionInstalled] = useState<string | undefined>(undefined);
  const [isCheckPerformed, setIsCheckPerformed] = useState<boolean>(false);

  const postMessagesQueue = useRef<any>([]);

  const config = { attributes: true, childList: false, subtree: false };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver((data: any) => {
    const classes = data[0].target.classList;
    const isOldWayOfDetection = classes.contains('cei');
    const versionRelatedClass = [...classes].find((className: string) => className.startsWith('version'));

    if (isOldWayOfDetection && versionRelatedClass) {
      const version = versionRelatedClass.split('-')[1];
      setVersionInstalled(version);
      setIsInstalled(!!version);
      setIsCheckPerformed(true);
    }
  });

  const handlePostMessage = (event: any): void => {
    if (window.location.href.indexOf(event.origin) === 0 && event.source === window) {
      // passed safety check, OK to process here
      postMessagesQueue.current = ([...postMessagesQueue.current, event.data]);

      if (!versionInstalled && postMessagesQueue.current.length > 0) {
        const extensionVersionMessage = postMessagesQueue.current.find((record: any) => !!record.latestExtensionVersion);

        if (!!extensionVersionMessage) {
          setVersionInstalled(extensionVersionMessage.latestExtensionVersion);
          setIsInstalled(!!extensionVersionMessage.latestExtensionVersion);
          setIsCheckPerformed(true);
        }
      }
    }
  };

  useEffect(() => {
    // Start observing the target node for configured mutations
    observer.observe(document.body, config);

    window.addEventListener('message', handlePostMessage, false);

    return () => {
      window.removeEventListener('message', handlePostMessage, false);
    }
  }, []);

  useEffect(() => {
    let checkTimerId: any;

    if (!isCheckPerformed && isInstalled === undefined) {
      checkTimerId = setTimeout(() => {
        setIsCheckPerformed(true);
        setIsInstalled(false);
      }, 3000);
    }

    return () => {
      checkTimerId && clearTimeout(checkTimerId);
    }
  }, [isCheckPerformed, isInstalled]);

  return {
    isInstalled,
    versionInstalled
  };
}