import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../data-access";
import { useMobileDevice, useUser } from "../../hooks";
import { SidebarMenu, TopBarPanel } from "../../ui";
import "./layout.scss";

interface LayoutProps {
  children: React.ReactNode;
}

const BurgerMenu = lazy(
  () => import("../../ui/mobile/burger-menu/burger-menu")
);

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  const { currentUser } = useUser();
  const isMobileResolution = useMobileDevice();
  const location = useLocation();
  const year = new Date().getFullYear();

  return (
    <div className="layout">
      {currentUser &&
        isMobileResolution &&
        location.pathname !== APP_ROUTES.paymentProcessing && (
          <Suspense fallback={null}>
            <BurgerMenu />
          </Suspense>
        )}
      <div className="wrapper">
        <SidebarMenu />
        <div className="content">
          <TopBarPanel />
          {children}
          <div className="content footer-content">
            Upwex © {year}. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
