import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appConfig } from "../../app-config";
import { APP_ROUTES } from "../../data-access";
import { useExtensionDetails, useMobileDevice, useUser } from "../../hooks";
import { storeGenericActions } from "../../store/slices/generic";
import { FormButton, Spinner } from "../../ui";
import { cookie, getTrialPlanDayTillExpired } from "../../util";
import { MajorNotification } from "./major-notification";
import "./notification-manager.scss";

const baseClass = "notification-manager";

export const NotificationManager = (): JSX.Element => {
  const { currentUser } = useUser();
  const {
    isInstalled: isExtensionInstalled,
    versionInstalled: extensionVersionInstalled,
  } = useExtensionDetails();
  const [isDetectingExtension, setIsDetectingExtension] =
    useState<boolean>(true);
  const [notificationsVisibility, setNotificationsVisibility] = useState<{
    [key: string]: boolean | undefined;
  }>({
    noExtension: undefined,
    trialPeriod: undefined,
    subscriptionExpired: undefined,
    upgradeExtensionVersion: undefined,
  });
  const isMobile = useMobileDevice();
  const dispatch = useDispatch();

  const currentPath = window.location.pathname;
  const isOldExtensionVersion =
    extensionVersionInstalled !== appConfig.actualExtensionVersion;

  const pluginCheckExcludedOnPages = [
    APP_ROUTES.paymentProcessing,
    APP_ROUTES.extensionGeneral,
    APP_ROUTES.extensionJobScoreDetails,
    APP_ROUTES.extensionUserProfile,
    APP_ROUTES.extensionAnalytics,
    APP_ROUTES.extensionDeals,
    APP_ROUTES.extensionCRM,
    APP_ROUTES.extensionPrompts,
    APP_ROUTES.extensionPromptCreate,
    APP_ROUTES.extensionPromptEdit,
    APP_ROUTES.extensionSettings,
  ];

  useEffect(() => {
    const detectingExtensionTimerId = setTimeout(() => {
      if (typeof isExtensionInstalled === "boolean") {
        setIsDetectingExtension(false);
      }
    }, 1500);

    return () => {
      clearTimeout(detectingExtensionTimerId);
    };
  }, [isExtensionInstalled]);

  useEffect(() => {
    if (isExtensionInstalled !== undefined) {
      dispatch(
        storeGenericActions.update({
          extensionInstalled: isExtensionInstalled,
          oldExtensionVersion: isOldExtensionVersion,
        })
      );
    }
    // initial status of extension installation is unknown (undefined)
    const showExtensionInstallNotification =
      !cookie.get("npn") &&
      !isDetectingExtension &&
      !isExtensionInstalled &&
      !pluginCheckExcludedOnPages.includes(currentPath);
    const showTrialPeriodNotification =
      !isDetectingExtension &&
      !!currentUser &&
      currentUser?.trial_expires_at !== null &&
      !pluginCheckExcludedOnPages.includes(currentPath);
    const showSubscriptionExpiredNotification =
      !isDetectingExtension &&
      !!currentUser &&
      currentUser?.active_licenses_count === 0 &&
      !pluginCheckExcludedOnPages.includes(currentPath);
    const showUpgradeExtensionNotification =
      !isDetectingExtension &&
      isExtensionInstalled &&
      isOldExtensionVersion &&
      !pluginCheckExcludedOnPages.includes(currentPath);

    setNotificationsVisibility({
      noExtension: !isMobile && showExtensionInstallNotification,
      trialPeriod: showTrialPeriodNotification,
      subscriptionExpired: showSubscriptionExpiredNotification,
      upgradeExtensionVersion: !isMobile && showUpgradeExtensionNotification,
    });
  }, [isDetectingExtension, currentUser, currentPath, isExtensionInstalled]);

  const handleInstallExtensionClick = (): void => {
    window.open(appConfig.extensionInstallLink, "_blank");
  };

  const subscriptionTrialPeriodLeft = getTrialPlanDayTillExpired(
    String(currentUser?.trial_expires_at)
  );

  const hideNoExtensionNotification = (): void => {
    setNotificationsVisibility({
      ...notificationsVisibility,
      noExtension: false,
    });
    cookie.set("npn", "skipped");
  };

  return (
    <div className={baseClass} style={{ display: "none" }}>
      {!isMobile &&
        isDetectingExtension &&
        !pluginCheckExcludedOnPages.includes(currentPath) && (
          <div className="detecting-extension">
            <Spinner /> Detecting extension...
          </div>
        )}
      {notificationsVisibility.noExtension && (
        <MajorNotification
          showIcon={false}
          theme="technical"
          closable={true}
          onClose={hideNoExtensionNotification}
        >
          <div className="row">
            <div className="column">
              <h2 className="title">Install Upwex Chrome Extension</h2>
              <span className="description">
                Please install the Upwex extension for use in Upwork. After
                installation, you will need to reload this page.
              </span>
            </div>
            <FormButton onClick={handleInstallExtensionClick}>
              Install
            </FormButton>
          </div>
        </MajorNotification>
      )}
      {notificationsVisibility.trialPeriod && (
        <MajorNotification>
          Your subscription plan: <strong>&nbsp;Trial</strong>. Your trial ends
          in <strong>&nbsp;{subscriptionTrialPeriodLeft} days</strong>.
        </MajorNotification>
      )}
      {notificationsVisibility.subscriptionExpired && (
        <MajorNotification theme="error">
          Your subscription has expired. You need to purchase a licence to use
          the extension.
        </MajorNotification>
      )}
    </div>
  );
};
